import { FC } from "react";

import List from "apps/website/components/base/List/List";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import Accordion, {
  IAccordion,
} from "apps/website/components/feature/Accordion/Accordion";

export interface IAccordionList {
  items: IAccordion[];
  hasBorderTop?: boolean;
}

const AccordionList: FC<IAccordionList> = ({ items, hasBorderTop = false }) => (
  <List component="AccordionList" className={`${hasBorderTop && "border-t-2 border-current"}`}>
    { items.map((item) => (
      <ListItem key={item.title}>
        <Accordion {...item} />
      </ListItem>
    )) }
  </List>
);

export default AccordionList;
