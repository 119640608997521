"use client";

import {
  createContext,
  FC,
  PropsWithChildren,
  useMemo,
} from "react";
// @ts-ignore
import GTMPlugin from "@analytics/google-tag-manager";
// @ts-ignore
import SegmentPlugin from "@analytics/segment";
import { Analytics, AnalyticsInstance } from "analytics";

import { ChatBot } from "../integrations/Chatbot";
import { HotJar } from "../integrations/Hotjar";
import { Wondering } from "../integrations/Wondering";

const GTM_ID = "GTM-TNCWDWQ";
const SEGMENT_ID = "WBEBcWsEVwXntxgmXSy3Tx8x0iiBlakD";

export const analyticsContext = createContext<AnalyticsInstance>(undefined as unknown as AnalyticsInstance);

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {

  const analytics = useMemo(() => Analytics({
    app: "katkin-site",
    plugins: [
      SegmentPlugin({ writeKey: SEGMENT_ID }),
      GTMPlugin({ containerId: GTM_ID }),
    ],
  }), []);

  return (
    <analyticsContext.Provider value={analytics}>
      <ChatBot />
      <HotJar />
      <Wondering />
      { children }
    </analyticsContext.Provider>
  );
};
