import Script from "next/script";

const ELEVIO_ID = "61af7b2903a84";
const DIXA_MESSENGER_TOKEN = "a84d69dd1f0047e68507f9185842050e";
const ELEVIO_MODULE_ID = "2";

export function ChatBot() {

  return (
    <>
      <Script
        id="chatbot"
        dangerouslySetInnerHTML={{
          __html: `
          !function(e,l,v,i,o,n){e[i]||(e[i]={}),e[i].account_id=n;var g,h;g=l.createElement(v),g.type="text/javascript",g.async=1,g.src=o+n,h=l.getElementsByTagName(v)[0],h.parentNode.insertBefore(g,h);e[i].q=[];e[i].on=function(z,y){e[i].q.push([z,y])}}(window,document,"script","_elev","https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=","${ELEVIO_ID}");
          `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function (w, d, s) {
            if (w._dixa_) return;
            w._dixa_ = {
              invoke: function (m, a) {
                w._dixa_.pendingCalls = w._dixa_.pendingCalls || [];
                w._dixa_.pendingCalls.push([m, a]);
              },
              addListener: function (event, listener) {
                w._dixa_.pendingAddListenerCalls = w._dixa_.pendingAddListenerCalls || [];
                w._dixa_.pendingAddListenerCalls.push([event, listener]);
              },
            };
            s = d.createElement('script');
            s.type = 'text/javascript';
            s.setAttribute('charset', 'utf-8');
            s.async = true;
            s.src = 'https://messenger.dixa.io/bootstrap.js';
              var before = d.getElementsByTagName('script')[0];
            before.parentNode.insertBefore(s, before);
          })(window, document);

          _dixa_.invoke('init', { messengerToken: '${DIXA_MESSENGER_TOKEN}', hideToggler: true, showWidgetCloseButton: true });
          function onDixaMessengerOpenChanged(open) {
            if(!open) {
              _elev.setSettings({hideLauncher: false});
              _elev.open();
              _dixa_.removeListener("on-widget-open-changed",onDixaMessengerOpenChanged);
            }
          }
          function openDixaMessengerWidget() {
            _elev.close();
            _elev.setSettings({hideLauncher: true});
            _dixa_.invoke('setWidgetOpen', true);
            _dixa_.addListener("on-widget-open-changed", onDixaMessengerOpenChanged);
          }
          window?._elev?.events = {
            moduleOpened: function(id) {
              if(id == ${ELEVIO_MODULE_ID})
              openDixaMessengerWidget();
              else
                console.log('Elevio module ' + id + ' clicked, but no action specified.');
            }
          };
          `,
        }}
      />
    </>
  );
}
