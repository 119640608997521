"use client";

import {
  FC,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "next/navigation";

import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

import SimpleCheckbox from "../../form/Checkbox/SimpleCheckbox/SimpleCheckbox";
import Column from "../../layout/Column/Column";
import Button from "../../base/Button/Button";
import Spacer from "../../layout/Spacer/Spacer";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Grid from "../../layout/Grid/Grid";

// Also contains the checkbox component for now for ease
const SuspendedSectionCheckboxEnabledCTA: FC = () => {
  const query = useSearchParams();
  const [ enableCta, setEnableCta ] = useState<boolean>(false);
  const catName = useMemo(() => {
    if (query.get("cat")) {
      return query.get("cat");
    }
    return "your cat";
  }, [ query ]);
  const { chainQueryParamsToUrl } = useQueryParams();

  const checkboxText = useMemo(() => `I understand that by cancelling my box, ${catName} will lose out on the benefits of fresh and it may take 1-2 weeks to reactivate my subscription.`, [ catName ]);

  const handleOnChange = useCallback((checked: boolean) => {
    setEnableCta(checked);
  }, []);

  const getButton = useCallback((isEnabled: boolean) => (isEnabled ? <Button to={chainQueryParamsToUrl("/forms/cancellation")}>Cancel my deliveries</Button> : <Button disabled={true}>Cancel my deliveries</Button>
  ), [ chainQueryParamsToUrl ]);

  return <Section component="SectionCheckboxEnabledCTA" theme="default">
    <Container>
      <Grid>
        <Column align={"center"}>
          <SimpleCheckbox label={checkboxText} optional={true} onChange={(event) => handleOnChange(event.target.checked)} name={""} labelSize={legacySizeCollectionMap.titleXs}></SimpleCheckbox>
          <Spacer size="xl"/>
          <Column direction={"row"}>
            <Button to={"/manage-plan"} design="underline">Back</Button>
            { getButton(enableCta) }
          </Column>
        </Column>
      </Grid>
    </Container>
  </Section>;
};

const SectionCheckboxEnabledCTA = () => (
  <Suspense>
    <SuspendedSectionCheckboxEnabledCTA />
  </Suspense>
);

export default SectionCheckboxEnabledCTA;
