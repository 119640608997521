"use client";

import {
  useState,
  FC,
  KeyboardEvent,
  useRef,
  ReactNode,
} from "react";

import useA11y from "apps/website/hooks/useA11y";
import List from "apps/website/components/base/List/List";
import ListItem from "apps/website/components/base/ListItem/ListItem";

import TextTitle from "../../base/Text/TextTitle/TextTitle";

export interface ITab {
  key: string;
  title: string;
  Content: ReactNode;
}

export interface ITabs {
  tabs: ITab[];
  defaultActiveTab?: string;
}

const Tabs: FC<ITabs> = ({ tabs, defaultActiveTab }) => {

  const { UUID } = useA11y();

  const [ activeTab, setActiveTab ] = useState(defaultActiveTab || tabs[0].key);

  const isActiveTab = (key: string) => key === activeTab;
  const tabStateClasses = (key: string) => (isActiveTab(key) ? "flex" : "hidden");
  const getButtonId = (key: string) => `tabs-button-${UUID}-${key}`;
  const getPanelId = (key: string) => `tabs-panel-${UUID}-${key}`;
  const tabButtons = useRef<HTMLButtonElement[]>([]);

  const onButtonArrowLeftDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const index = Number((event.target as HTMLButtonElement).getAttribute("data-index"));
    if (index > 0) {
      const newIndex = index - 1;
      const newKey = tabButtons.current[newIndex];
      return newKey?.focus();
    }
  };

  const onButtonArrowRightDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const index = Number((event.target as HTMLButtonElement).getAttribute("data-index"));
    if (index < tabs.length) {
      const newIndex = index + 1;
      const newKey = tabButtons.current[newIndex];
      return newKey.focus();
    }
  };

  const onButtonKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (![ "ArrowLeft", "ArrowRight", "Tab" ].includes(event.key)) return;
    if (event.key === "ArrowLeft") return onButtonArrowLeftDown(event);
    if (event.key === "ArrowRight") return onButtonArrowRightDown(event);
  };

  return (
    <div data-component={ Tabs.name }>
      <div className="flex justify-center relative">
        <nav className="flex overflow-auto pb-4 w-fit px-4">
          <List direction="row" columns="noWrap" className="space-x-2">
            { tabs.map((tab, index) => (
              <ListItem key={ tab.key }>
                <button
                  data-index={ index }
                  ref={ (tabButton) => {
                    if (tabButton) {
                      tabButtons.current[index] = tabButton;
                    }
                  } }
                  id={ getButtonId(tab.key) }
                  aria-controls={ getPanelId(tab.key) }
                  aria-selected={ isActiveTab(tab.key) }
                  role="tab"
                  onClick={ () => setActiveTab(tab.key) }
                  onKeyDown={ onButtonKeyDown }
                  tabIndex={ isActiveTab(tab.key) ? 0 : -1 }
                  className={ `border-2 border-solid border-dark-grey text-md px-4 py-1 ${isActiveTab(tab.key) ? "bg-brand" : "text-dark-grey"}` }
                >
                  <TextTitle tag="span" size={ 3 }>{ tab.title }</TextTitle>
                </button>
              </ListItem>
            )) }
          </List>
        </nav>
        <div className="absolute right-0 top-0 bg-gradient-to-r from-transparent to-white h-full w-8"></div>
      </div>
      <div>
        { tabs.map((tab, index) => (
          <div
            key={ tab.key }
            id={ getPanelId(tab.key) }
            aria-labelledby={ getButtonId(tab.key) }
            className={ tabStateClasses(tab.key) }
            aria-hidden={ !isActiveTab(tab.key) }
            tabIndex={ isActiveTab(tab.key) ? 0 : -1 }
            data-index={ index }
            role="tabpanel"
          >
            { tab.Content }
          </div>
        )) }
      </div>
    </div>
  );
};

export default Tabs;
