import {
  render,
  MARK_BOLD,
  MARK_LINK,
  MARK_STRIKE,
  MARK_UNDERLINE,
  NODE_UL,
  NODE_OL,
  NODE_LI,
  NODE_HEADING,
  NODE_PARAGRAPH,
  MARK_CODE,
} from "storyblok-rich-text-react-renderer";
import Link from "next/link";
import { StoryblokComponent } from "@storyblok/react";
import { ReactNode } from "react";

import { Size } from "apps/website/components/layout/Spacer/Spacer.map";
import Text from "apps/website/components/base/Text/Text";
import List from "apps/website/components/base/List/List";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import { Display } from "apps/website/components/base/Text/Text.map";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import StoryModal from "apps/website/components/feature/Modal/StoryModal/StoryModal";
import { IStoryBlokRelation } from "apps/website/components/page/api.types";

import { redirectToCorrectInternalPath } from "./links";

interface IRichTextOptionLinks {
  display?: Display;
  transformer?: (string: string) => string;
}

interface IRichTextInterpolationOptions {
  values: Record<string, string>;
}

interface IRichTextTagOptions {
  MARK_CODE?: {
    display?: "default" | "subtitle"
  }
}
export interface IRichTextOptions {
  interpolation?: IRichTextInterpolationOptions;
  unwrapResolvers?: boolean;
  links?: IRichTextOptionLinks;
  tags?: IRichTextTagOptions;
}

interface IRichTextInlineStoryModal extends Record<string, unknown> {
  _uid: string;
  button_text: string;
  story: IStoryBlokRelation<ReactNode>;
}

type RichTextInlineStoryModal = Record<keyof IRichTextInlineStoryModal, unknown>;

export const renderStoryBlokRichText = (richText: string, options?: IRichTextOptions) => render(richText, {
  markResolvers: {
    [MARK_CODE]: (children) => (children !== null ? (options?.unwrapResolvers ? <>{ children }</> : <><Text tag="span" size="inherit" align="inherit" display={options?.tags?.MARK_CODE?.display || "subtitle"}>{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</Text></>) : null),
    [MARK_BOLD]: (children) => (children !== null ? <Text tag="strong" display="subtitle" size="inherit">{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</Text> : null),
    [MARK_STRIKE]: (children) => (children !== null ? <Text tag="s" className="line-through" size="inherit">{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</Text> : null),
    [MARK_UNDERLINE]: (children) => (children !== null ? <Text tag="u" className="underline" size="inherit">{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</Text> : null),
    [MARK_LINK]: (children, props) => {
      const { linktype, href } = props;
      const transformedHref = options?.links?.transformer && href ? options.links.transformer(href) : href;
      if (linktype === "email") {
        return <a href={`mailto:${transformedHref}`}><Text tag="span" display={options?.links?.display ?? "subtitle"} className="underline" size="inherit">{ children }</Text></a>;
      }
      if (href?.match(/^(https?:)?\/\//)) {
        return <a href={transformedHref} target="_blank" rel="nofollow noopener noreferrer"><Text tag="span" display={options?.links?.display ?? "subtitle"} className="underline" size="inherit">{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</Text></a>;
      }
      return <Link href={options?.links?.transformer && href ? options.links.transformer(redirectToCorrectInternalPath(href)) : redirectToCorrectInternalPath(href ?? "")}><Text tag="span" display={options?.links?.display ?? "subtitle"} className="underline" size="inherit">{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</Text></Link>;
    },
  },
  nodeResolvers: {
    [NODE_UL]: (children) => (children !== null ? (options?.unwrapResolvers ? <>{ children }</> : <List listStyle="disc" className="mb-4 ml-8">{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</List>) : null),
    [NODE_OL]: (children) => (children !== null ? (options?.unwrapResolvers ? <>{ children }</> : <List tag="ol" listStyle="decimal" className="mb-4 ml-8">{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</List>) : null),
    [NODE_LI]: (children) => (children !== null ? (options?.unwrapResolvers ? <>{ children }</> : <ListItem display="list-item">{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</ListItem>) : null),
    [NODE_HEADING]: (children, { level }) => (children !== null ? (options?.unwrapResolvers ? <>{ children }</> : <Text tag={`h${level}`} display="subtitle" size={level === 3 ? "md" : "default"} className="mb-4">{ options?.interpolation ? interpolateString(`${children}`, options.interpolation.values, Interpolator.FRONTEND) : children }</Text>) : null),
    [NODE_PARAGRAPH]: (children) => (children !== null ? (options?.unwrapResolvers ? <>{ children }</> : <Text size="inherit" align="inherit">{ children }</Text>) : null),
  },
  blokResolvers: {
    rich_text_inline_story_modal: ( // eslint-disable-line
      props: RichTextInlineStoryModal,
    ) => (
      <StoryModal id={props._uid as string} buttonText={props.button_text as string} buttonPadding={(props?.padding || "none") as Size}>
        <StoryblokComponent blok={(props.story as IStoryBlokRelation<ReactNode>).content} />
      </StoryModal>
    ),
  },
});
