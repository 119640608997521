import clsx from "clsx";
import { FC } from "react";

import Tag from "apps/website/components/base/Tag/Tag";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import Icon from "apps/website/components/base/Icon/Icon";

interface ITickBoxSelectOption {
  name: string;
  secondaryText?: string;
  tertiaryText?: string;
  tag?: string;
  secondaryTag?: string;
  current?: boolean;
}

export const TickBoxSelectOption: FC<ITickBoxSelectOption> = (
  {
    name,
    secondaryText,
    tertiaryText,
    tag,
    secondaryTag,
  },
) => (
  <>
    <div className={ clsx(
      "bg-white cursor-pointer box-border border-mid-grey border-2 p-4 flex flex-1 items-center border-solid justify-between",
      "peer-checked:border-black relative group",
    ) }>
      <div className="flex space-x-2 items-center">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center border border-mid-grey border-2 peer-checked:group-[]:border-black peer-checked:group-[]:bg-black h-6 w-6 rounded-full">
            <Icon icon="tickAlt" className="hidden peer-checked:group-[]:block" size="2xsmall" color="secondary" />
          </div>
        </div>
        <TextSubtitle tag="span" size={ 2 }>{ name }</TextSubtitle>
        { tag && (
          <Tag theme="green" size="small">{ tag }</Tag>
        ) }
      </div>
      <div className="flex items-center space-x-2">
        { tertiaryText && (
          <div className="text-dark-grey">
            <TextBody tag="del" size={ 2 } color="inherit">{ tertiaryText }</TextBody>
          </div>
        ) }
        { secondaryText && (
          <TextSubtitle tag="span" size={ 2 }>{ secondaryText }</TextSubtitle>
        ) }
        { secondaryTag && (
          <Tag theme="pink" size="small">{ secondaryTag }</Tag>
        ) }
      </div>
    </div>
  </>
);
