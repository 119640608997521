"use client";

import { FC, useState } from "react";
import Script from "next/script";

import { IImageProps } from "apps/website/components/base/Image/Image";
import { ITagProps } from "apps/website/components/base/Tag/Tag";
import { ILink } from "apps/website/components/base/Button/Button.map";
import {
  IAccordion,
} from "apps/website/components/feature/Accordion/Accordion";
import Card, { CardCtaAction } from "apps/website/components/feature/Card/Card";
import ProductModal from
  "apps/website/components/feature/Modal/ProductModal/ProductModal";
import { IVideoPath } from "apps/website/components/base/Video/Video";
import {
  STORYBLOK_PRODUCTS_PRETTY_ROOT,
} from "apps/website/constants/storyblok";
import { slugify } from "apps/website/utils/content/text";
import { useTrustpilot } from "apps/website/hooks/useTrustpilot";
import IconButton from "apps/website/components/base/IconButton/IconButton";

export interface IProductCard {
  title: string;
  tagline?: string;
  description: string;
  details?: string;
  image: IImageProps;
  gallery?: IImageProps[];
  hoverMedia?: IImageProps | IVideoPath;
  tag?: ITagProps;
  ctas: ILink[];
  link?: string;
  ctaAction?: CardCtaAction;
  faqs: IAccordion[],
}

const ProductCard: FC<IProductCard> = ({
  title,
  tagline,
  description,
  details,
  image,
  gallery,
  hoverMedia,
  tag,
  link,
  ctas,
  ctaAction = "modal",
  faqs,
}) => {

  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const trustpilot = useTrustpilot();

  const basePathLink = `/${link}`;

  const productCardFooter = () => (
    <>
      { (ctaAction === "link" && link) ? (
        <div className="w-full">
          <IconButton icon="arrowRightCircle" size="small" label="Navigate to product details" justify="start" to={basePathLink}>
        Learn more
          </IconButton>
        </div>
      ) : (
        <ProductModal
          image={gallery?.[0] || image}
          title={title}
          tagline={tagline}
          body={details || description}
          ctas={ctas}
          faqs={faqs}
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
        />
      ) }
    </>
  );

  return (
    <>
      <Script
        id={`jsonld-product-${slugify(title)}`}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Product",
          name: title,
          description: details || description,
          url: `/${STORYBLOK_PRODUCTS_PRETTY_ROOT}`,
          image: image.src,
          brand: {
            "@type": "Brand",
            name: "KatKin",
          },
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: trustpilot.score,
            reviewCount: trustpilot.reviews,
          },
        }),
        }}
      />
      <Card
        component="ProductCard"
        title={title}
        description={description}
        image={image}
        imageRatio="3/4"
        hoverMedia={hoverMedia}
        theme="product-card"
        link={link && basePathLink}
        ctaAction={ctaAction}
        tag={tag}
        align={(ctaAction === "link" && link) ? "default" : "center"}
        divider={(ctaAction === "link" && link) ? "default" : "small"}
        onClick={() => setIsModalOpen(true)}
        Footer={productCardFooter}
      />
    </>
  );
};

export default ProductCard;
