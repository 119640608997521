import Script from "next/script";

const HOTJAR_ID = 2215112;
const HOTJAR_SV = 6;

// Component which hotjar script
export function HotJar() {
  return (
    <Script
      id="hotjar-setup"
      dangerouslySetInnerHTML={{
        __html: `(function (h, o, t, j, a, r) {
          h.hj = h.hj || function () {
              (h.hj.q = h.hj.q || []).push(arguments)
          };
          h._hjSettings = {hjid: ${HOTJAR_ID}, hjsv: ${HOTJAR_SV}};
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script');
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`,
      }}/>
  );
}
