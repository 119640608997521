"use client";

import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";

import { useTextInput } from "@/hooks";
import {
  BatchLookupAPI,
  IBatchTestResult,
  IBatchTestResultError,
} from "apps/website/api/BatchLookup";
import { PublicConfig } from "apps/website/config/public";

export enum BatchLookupState {
  READY,
  FOUND,
  NOT_FOUND,
  UNKNOWN_ERROR,
  IN_PROGRESS,
}

export function useBatchTestResult() {
  const [ searchString, setSearchString ] = useTextInput("");
  const [ responseData, setResponseData ] = useState<IBatchTestResult | undefined>();
  const [ errorData, setErrorData ] = useState<IBatchTestResultError | undefined>();
  const [ state, setState ] = useState<BatchLookupState>(BatchLookupState.READY);
  const batchApi = useMemo(() => new BatchLookupAPI(PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL), []);
  const search = useCallback(async () => {
    setState(BatchLookupState.IN_PROGRESS);
    try {
      const data = await batchApi.getBatchInformation(searchString);
      setResponseData(data);
      setState(BatchLookupState.FOUND);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          setErrorData(error.response.data);
          setState(BatchLookupState.NOT_FOUND);
          return;
        }
      }
      setErrorData({ errorMessage: "An unexpected error occurred." });
      setState(BatchLookupState.UNKNOWN_ERROR);
    }
  }, [ searchString, batchApi ]);

  const errorResult = useMemo(() => (
    errorData === undefined ? undefined : {
      ...errorData,
    }), [ errorData ]);

  const result = useMemo(() => (
    responseData === undefined ? undefined : {
      batchNumber: responseData.batchNumber,
      productionDate: dayjs(responseData.batchDate, "YYYY-MM-DD"),
      bestBeforeDate: dayjs(responseData.bestBefore, "YYYY-MM-DD"),
      testAuthority: responseData.testAuthority,
      testResult: responseData.testResult,
    }), [ responseData ]);

  return { searchString, setSearchString, state, search, result, errorResult };
}
