"use client";

import {
  FC,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  BodyType,
  Breed,
  calculateSuggestedCaloriesForKitten,
  calculateSuggestedCaloriesForGrownCat,
  calculateCatWeight,
  calculateTraySize,
  IMealPlan,
  PouchSize,
} from "@./cat-calculations";
import {
  BREEDS,
  CAT_ACTIVITY_LEVEL,
  CAT_FOOD_BRAND,
  CAT_FOOD_TYPE,
  TBodyType,
  TFoodBrand,
  TFoodType,
  TLifeStyle,
  competitorMap,
} from "libs/cat-calculations/src/lib/cat-calculations.constants";
import { DisplayState } from "@/constants/state";
import { useAPI } from "apps/website/hooks/useAPI";

import Container from "../../layout/Container/Container";
import Grid from "../../layout/Grid/Grid";
import Select from "../../form/Select/Select";
import Column from "../../layout/Column/Column";
import RadioSelect from "../../form/RadioSelect/RadioSelect";
import TextTitle from "../../base/Text/TextTitle/TextTitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import Spacer from "../../layout/Spacer/Spacer";
import { IconSvg } from "../../base/Icon/Icon.map";
import Button from "../../base/Button/Button";
import { AlertPrompt } from "../AlertPrompt/AlertPrompt";

import PriceCalculatorResult from "./PriceCalculatorResult/PriceCalculatorResult";

type TKatKinPrices = Record<PouchSize, number>;

const IS_KITTEN = {
  YES: "Yes",
  NO: "No",
} as const;

const CAT_BODY_TYPE_MAP: Partial<
Record<TBodyType, { icon: IconSvg; title: string }>
> = {
  SKINNY: {
    title: "Skinny",
    icon: "catWeightSkinny",
  },
  JUST_RIGHT: {
    title: "Just right",
    icon: "catWeightJustRight",
  },
  CHUBBY: {
    title: "Chubby",
    icon: "catWeightChubby",
  },
  OBESE: {
    title: "Obese",
    icon: "catWeightObese",
  },
};

type TIsKitten = keyof typeof IS_KITTEN;

const allValuesAreDefined = (...values: (string | number | undefined)[]) => (
  values.every((value) => typeof value !== "undefined")
);

const entriesToOptions = (entries: Record<string, string>) => (
  Object.entries(entries).map(([ value, name ]) => ({ name, value }))
);

const PriceCalculator: FC = () => {
  const [ breed, setBreed ] = useState<Breed>();
  const [ isKitten, setIsKitten ] = useState<TIsKitten>();
  const [ ageMonths, setAgeMonths ] = useState<number>();
  const [ ageWeeks, setAgeWeeks ] = useState<number>();
  const [ activityLevel, setActivityLevel ] = useState<TLifeStyle>();
  const [ bodyCondition, setBodyCondition ] = useState<BodyType>();
  const [ foodType, setFoodType ] = useState<TFoodType>();
  const [ foodBrand, setFoodBrand ] = useState<TFoodBrand>();
  const [ isFormValid, setIsFormValid ] = useState(false);
  const [ formState, setFormState ] = useState<DisplayState>(DisplayState.READY);
  const [ formError, setFormError ] = useState<string>();
  const [ traySize, setSetTraySize ] = useState<IMealPlan>();
  const [ katkinPrices, setKatkinPrices ] = useState<TKatKinPrices>();

  const api = useAPI();

  useEffect(() => {
    // Reset values changing between cat and kitten
    setAgeMonths(undefined);
    setAgeWeeks(undefined);
    setActivityLevel(undefined);
    setBodyCondition(undefined);
    setFoodType(undefined);
    setFoodBrand(undefined);
  }, [ isKitten ]);

  useEffect(() => {
    const canCompareKitten =
      isKitten === "YES" &&
      allValuesAreDefined(breed, ageMonths, ageWeeks, foodType, foodBrand);
    const canCompareCat =
      isKitten === "NO" &&
      allValuesAreDefined(
        breed,
        bodyCondition,
        activityLevel,
        foodType,
        foodBrand,
      );
    setIsFormValid(canCompareKitten || canCompareCat);
  }, [
    isKitten,
    breed,
    ageMonths,
    ageWeeks,
    foodType,
    foodBrand,
    bodyCondition,
    activityLevel,
  ]);

  useEffect(() => {
    const effectGetPrices = async () => {
      const katkinPriceObject: Partial<TKatKinPrices> = {};
      const pouchData = await api.Customer.getAvailableTraySizes("Plan42x42");

      pouchData.forEach((pouch) => {
        katkinPriceObject[pouch.traySize as PouchSize] = +(
          pouch.price / 42
        ).toFixed(2);
      });

      setKatkinPrices(katkinPriceObject as TKatKinPrices);
    };

    void effectGetPrices();
  }, []);

  const signUpFlowParams = useMemo<string>(() => {
    // const paramStrings: string[] = [
    //   `catBreed=${breed}`,
    //   `catLifeStage=${isKitten === "YES" ? "kitten" : "adult"}`,
    //   `catFoodType=${foodType}`,
    //   `catFoodBrand=${foodBrand}`,
    // ];
    // if (ageMonths) { paramStrings.push(`catAgeMonths=${ageMonths}`); }
    // if (ageWeeks) { paramStrings.push(`catAgeWeeks=${ageWeeks}`); }
    // if (activityLevel) { paramStrings.push(`catActivity=${activityLevel}`); }
    // if (bodyCondition) { paramStrings.push(`catBodyCondition=${bodyCondition}`); }
    const paramStrings: string[] = [];

    return paramStrings.join("&");
  }, [ breed, isKitten, ageMonths, ageWeeks, activityLevel, bodyCondition, foodType, foodBrand ]);

  const onCompareBrands = () => {
    setFormState(DisplayState.PROCESSING);
    if (typeof isKitten === "undefined") {
      setFormError("No age selected.");
      setFormState(DisplayState.READY);
      return;
    }
    if (!breed) {
      setFormError("No breed selected.");
      setFormState(DisplayState.READY);
      return;
    }
    const type = isKitten === "YES" ? "kitten" : "adult";
    const estimatedCatWeight = calculateCatWeight(
      breed,
      {
        type,
        months: type === "kitten" ? ageMonths : undefined,
        weeks: type === "kitten" ? ageWeeks : undefined,
      },
      "BOY",
    );
    let caloriesNeeded;
    if (isKitten === "YES") {
      if (!ageMonths || !ageWeeks) {
        setFormError("Missing age details.");
        setFormState(DisplayState.READY);
        return;
      }

      caloriesNeeded = calculateSuggestedCaloriesForKitten(estimatedCatWeight, {
        years: 0,
        months: ageMonths,
        weeks: ageWeeks,
      });
    } else {
      if (!bodyCondition || !activityLevel) {
        setFormError("Missing Body condition or Activity level.");
        setFormState(DisplayState.READY);
        return;
      }
      caloriesNeeded = calculateSuggestedCaloriesForGrownCat(
        estimatedCatWeight,
        bodyCondition,
        activityLevel,
      );
    }
    if (!caloriesNeeded) {
      setFormError(
        "Couldn't calculate calories needed, please check all fields are filled out correctly.",
      );
      setFormState(DisplayState.READY);
      return;
    }

    setSetTraySize(calculateTraySize(caloriesNeeded));
    setFormState(DisplayState.COMPLETE);
  };

  return (
    <Container component={ PriceCalculator.name }>
      <Grid>
        <Column>
          <TextTitle size={ 1 } align="center">
            How KatKin compares to your current cat food
          </TextTitle>
          <Spacer size="md" />
          <TextBody size={ 1 } align="center">
            Answer the questions below. And see how much you could save by
            feeding fresh.
          </TextBody>
          <Spacer size="lg" />
          { formState === DisplayState.COMPLETE ? (
            <div>
              { foodBrand && traySize && foodType && katkinPrices && (
                <PriceCalculatorResult
                  katkinPrice={ katkinPrices[traySize.size] }
                  katkinCalories={ traySize.calories }
                  competitorPortion={
                    competitorMap?.[foodBrand]?.[foodType]?.[traySize.size]
                  }
                  competitor={ foodBrand }
                  signUpFlowParams={ signUpFlowParams }
                />
              ) }
            </div>
          ) : (
            <>
              <Select
                label="What breed is your cat"
                labelStyle="alternative"
                hideLabel={ false }
                name="breed"
                placeholder="Select a breed"
                selected={ breed }
                options={ BREEDS.map(({ text, value }) => ({
                  name: text,
                  value,
                })) }
                onChange={ (event) => setBreed(event.target.value as Breed) }
              />
              <Spacer size="md" />

              <RadioSelect
                label="Are they under 1 years old"
                labelStyle="alternative"
                optionSize="[2,2]"
                hideLabel={ false }
                name="isKitten"
                options={ entriesToOptions(IS_KITTEN) }
                onChange={ (event) => {
                  setIsKitten(event.target.value as TIsKitten);
                } }
              />
              <Spacer size="md" />

              { typeof breed !== "undefined" &&
                typeof isKitten !== "undefined" && (
                <>
                  { isKitten === "YES" ? (
                    <>
                      <Grid>
                        <Column spans={ 6 }>
                          <Select
                            label="Months"
                            labelStyle="alternative"
                            hideLabel={ false }
                            name="activityLevel"
                            placeholder="Months"
                            selected={ `${ageMonths}` }
                            options={ Array.from(Array(12)).map(
                              (_, index) => ({
                                name: `${index}`,
                                value: `${index}`,
                              }),
                            ) }
                            onChange={ (event) => setAgeMonths(+event.target.value)
                            }
                          />
                        </Column>
                        <Column spans={ 6 }>
                          <Select
                            label="Weeks"
                            labelStyle="alternative"
                            hideLabel={ false }
                            name="activityLevel"
                            placeholder="Weeks"
                            selected={ `${ageWeeks}` }
                            options={ Array.from(Array(4)).map((_, index) => ({
                              name: `${index}`,
                              value: `${index}`,
                            })) }
                            onChange={ (event) => setAgeWeeks(+event.target.value)
                            }
                          />
                        </Column>
                      </Grid>
                      <Spacer size="md" />
                    </>
                  ) : (
                    <>
                      <Select
                        label="How active are they?"
                        labelStyle="alternative"
                        hideLabel={ false }
                        name="activityLevel"
                        placeholder="Select level of activity"
                        selected={ activityLevel }
                        options={ entriesToOptions(CAT_ACTIVITY_LEVEL) }
                        onChange={ (event) => setActivityLevel(event.target.value as TLifeStyle)
                        }
                      />
                      <Spacer size="md" />

                      { activityLevel && (
                        <>
                          <RadioSelect
                            label="What's their body condition score?"
                            labelStyle="alternative"
                            optionSize="[4,4]"
                            hideLabel={ false }
                            name="bodyCondition"
                            options={ Object.entries(CAT_BODY_TYPE_MAP).map(
                              ([ value, data ]) => ({
                                value,
                                name: data.title,
                                icon: data.icon,
                              }),
                            ) }
                            onChange={ (event) => {
                              setBodyCondition(
                                event.target.value as TBodyType,
                              );
                            } }
                          />
                          <Spacer size="md" />
                        </>
                      ) }
                    </>
                  ) }

                  { ((isKitten === "YES" &&
                      allValuesAreDefined(breed, ageMonths, ageWeeks)) ||
                      (isKitten === "NO" &&
                        allValuesAreDefined(
                          breed,
                          bodyCondition,
                          activityLevel,
                        ))) && (
                    <>
                      <Select
                        label="What do they currently eat"
                        labelStyle="alternative"
                        hideLabel={ false }
                        name="currentlyEatType"
                        placeholder="Select a type of food"
                        selected={ foodType }
                        options={ entriesToOptions(CAT_FOOD_TYPE) }
                        onChange={ (event) => setFoodType(event.target.value as TFoodType)
                        }
                      />
                      <Spacer size="md" />

                      <Select
                        label="Made by..."
                        labelStyle="alternative"
                        hideLabel={ false }
                        name="currentlyEatBrand"
                        placeholder="Select a brand"
                        selected={ foodBrand }
                        options={ entriesToOptions(CAT_FOOD_BRAND) }
                        onChange={ (event) => setFoodBrand(event.target.value as TFoodBrand)
                        }
                      />
                      <Spacer size="lg" />
                    </>
                  ) }
                </>
              ) }

              { isFormValid && (
                <Grid>
                  <Column align="center">
                    <Button onClick={ onCompareBrands } state={ formState } color="secondary">
                      Compare
                    </Button>

                    { formError && (
                      <>
                        <Spacer size="lg" />
                        <AlertPrompt style="error">{ formError }</AlertPrompt>
                      </>
                    ) }
                  </Column>
                </Grid>
              ) }
            </>
          ) }
        </Column>
      </Grid>
    </Container>
  );
};

export default PriceCalculator;
