"use client";

import { Dayjs } from "dayjs";
import { FC, PropsWithChildren } from "react";

import { BatchTestStatus } from "apps/website/api/BatchLookup";

import Container from "../../layout/Container/Container";
import Text from "../../base/Text/Text";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";

interface BatchTestResultProps {
  batchNumber: string;
  productionDate?: Dayjs;
  bestBeforeDate?: Dayjs;
  testAuthority?: string;
  testResult?: BatchTestStatus;
}

const dateFormat = "DD.MM.YY";

export const BatchTestResult: FC<BatchTestResultProps> = ({
  batchNumber,
  productionDate,
  bestBeforeDate,
  testAuthority,
  testResult,
}) => (
  <Container className="bg-white p-4">
    <Grid>
      <Column spans={{ lg: 6 }} className="lg:order-last">
        <Grid>
          <Column spans={4}>
            <BatchTestResultColumn
              label="Tested By:"
            >
              <div className="mt-1">
                <a href="https://www.eurofins.com" target="_blank" rel="noreferrer">
                  <Text display="subtitle" align="center" className="underline">
                    { testAuthority }
                  </Text>
                </a>
              </div>
            </BatchTestResultColumn>
          </Column>
          <Column spans={8}>
            <BatchTestResultColumn
              label="Result of Tests:"
            >
              <div className={`mt-1 ${testResult === BatchTestStatus.PASS ? "bg-green" : "bg-pink"}`}>
                <Text display="subtitle" align="center" className="px-18">
                  { testResult }
                </Text>
              </div>
            </BatchTestResultColumn>
          </Column>
        </Grid>
      </Column>
      <Column spans={{ lg: 6 }} className="lg:order-first">
        <Grid>
          <Column spans={4}>
            <BatchTestResultColumn
              label="Batch No:"
              value={`BN${batchNumber}`}
            />
          </Column>
          <Column spans={4}>
            <BatchTestResultColumn
              label="Made On:"
              value={productionDate?.format(dateFormat)}
            />
          </Column>
          <Column spans={4}>
            <BatchTestResultColumn
              label="Best Before:"
              value={bestBeforeDate?.format(dateFormat)}
            />
          </Column>
        </Grid>
      </Column>
    </Grid>
  </Container>
);

interface BatchTestResultColumnProps {
  label: string;
  value?: string;
  classes?: string;
}

const BatchTestResultColumn: FC<PropsWithChildren<BatchTestResultColumnProps>> = ({
  label,
  children,
  value,
}) => (
  <div>
    <Text className="uppercase" size="4xs" align="center">
      { label }
    </Text>
    { value && (
      <div className="mt-1">
        <Text display="subtitle" align="center">
          { value }
        </Text>
      </div>
    ) }
    { children }
  </div>
);
