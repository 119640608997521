import { FC, useEffect } from "react";

import { IImageProps } from "apps/website/components/base/Image/Image";
import { ITagProps } from "apps/website/components/base/Tag/Tag";
import useModal from "apps/website/hooks/useModal";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Button from "apps/website/components/base/Button/Button";
import {
  IAccordion,
} from "apps/website/components/feature/Accordion/Accordion";
import AccordionList from
  "apps/website/components/feature/Accordion/AccordionList/AccordionList";
import IconButton from "apps/website/components/base/IconButton/IconButton";
import useFocusTrap from "apps/website/hooks/useFocusTrap";
import TextImageModal from
  "apps/website/components/feature/Modal/TextImageModal/TextImageModal";

export interface IProductModal {
  tag?: ITagProps;
  title: string;
  tagline?: string;
  body: string;
  image: IImageProps;
  isOpen?: boolean;
  onClose?: () => void;
  ctas?: ILink[],
  faqs?: IAccordion[],
}

const ProductModal: FC<IProductModal> = ({ tag, onClose, title, tagline, body, image, ctas, faqs, isOpen = false }) => {

  const { modalId, modalTitleId, modalDescriptionId, modalButtonId, modalIsOpen, setModalIsOpen } = useModal(isOpen);
  const { initFocusTrap, destroyFocusTrap } = useFocusTrap();

  useEffect(() => {
    if (modalIsOpen) {
      initFocusTrap(modalId);
    } else {
      destroyFocusTrap(modalButtonId);
      if (onClose) {
        onClose();
      }
    }
  }, [ modalIsOpen, onClose ]);

  return (
    <>
      <IconButton id={modalButtonId} icon="arrowRightCircle" size="small" label="Open product details" onClick={() => setModalIsOpen(!modalIsOpen)}>
        See more
      </IconButton>
      <TextImageModal
        component="ProductModal"
        modalId={modalId}
        modalTitleId={modalTitleId}
        modalDescriptionId={modalDescriptionId}
        onClose={() => { setModalIsOpen(false); }}
        isOpen={modalIsOpen}
        image={image}
        title={title}
        tagline={tagline}
        tag={tag}
        body={body}
      >
        <Spacer size="lg" />
        { ctas && (
          ctas.map((cta) => (
            <div key={cta.id} data-ctas>
              <Button to={cta.to} color={cta.color} design={cta.design} data-testid={cta.testId}>{ cta.name }</Button>
              <Spacer size="md" />
            </div>
          ))
        ) }
        { faqs && (
          <div data-faqs>
            <Spacer size="xl" />
            <AccordionList items={faqs} hasBorderTop />
          </div>
        ) }
      </TextImageModal>
    </>
  );
};

export default ProductModal;
