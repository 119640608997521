"use client";

import { useEffect } from "react";
import Script from "next/script";

import { useUserStore } from "libs/state/src/lib/stores/useUserStore";

declare global {
  interface Window {
    wondering: any; // eslint-disable-line
  }
}

const WONDERING_ID = "3ebbd4361527450297830affac676d20";
// Component which loads Wondering script
export function Wondering() {

  const { legacyId } = useUserStore();

  useEffect(() => {
    if (window?.wondering) {
      window.wondering("identify", `${legacyId}`);
    }
  }, [ legacyId ]);
  return (
    <Script
      id="wondering-setup"
      dangerouslySetInnerHTML={{
        __html: `(function(w,o,n,d,e,r,ing) {
          window.wondering = function(){W._q.push(arguments)}
          var W = window.wondering; W.wonderingID = n; W.env = o; W._q = [];
          s=w.createElement('script');
          s.async=!0;s.src=o+'?wid='+W.wonderingID;
          f=w.getElementsByTagName('script')[0];
          f.parentNode.insertBefore(s, f);
      })(document, 'https://cdn.wondering.com/ribbon.js', '${WONDERING_ID}');`,
      }}/>
  );
}
