import {
  BreakpointSpan,
} from "apps/website/components/layout/Column/Column.map";

export type OptionColumnSize = "full" | "[4,4]" | "[3,3]" | "[2,2]";
export type ColumnSize = "full" | "half" | "breakout";

export const groupedFieldSpanObjectMap: Record<OptionColumnSize, BreakpointSpan> = {
  full: { default: 12 },
  "[2,2]": { default: 6 },
  "[3,3]": { default: 4 },
  "[4,4]": { default: 3 },
};

export const fieldSpanObjectMap: Record<ColumnSize, BreakpointSpan> = {
  full: { default: 12 },
  breakout: { default: 12 },
  half: { default: 6 },
};
