"use client";

import { FC, useState } from "react";
import Link from "next/link";

import { Theme } from "apps/website/maps/Theme.map";

import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Column from "../../layout/Column/Column";
import FoodPortionCalculator from "../../feature/FoodPortionCalculator/FoodPortionCalculator";
import Grid from "../../layout/Grid/Grid";
import {
  AvailableTag,
  legacySizeCollectionMap,
} from "../../base/Text/Text.map";
import Text from "../../base/Text/Text";
import Spacer from "../../layout/Spacer/Spacer";
import Button from "../../base/Button/Button";

export interface ISectionFoodPortionCalculatorProps {
  title: string;
  titleTag?: AvailableTag;
  theme?: Theme;
  body: string;
}

const SectionFoodPortionCalculator: FC<ISectionFoodPortionCalculatorProps> = ({ title, titleTag = "h2", body, theme = "brand" }) => {
  const [ showFoodPortionCalculator, setShowFoodPortionCalculator ] = useState(false);
  return (
    <Section component={SectionFoodPortionCalculator.name} theme={theme}>
      <Container>
        <Grid>
          <Column>
            { title && (
              <>
                <Text tag={titleTag} display="title" size={legacySizeCollectionMap.titleLg} align="center">
                  { title }
                </Text>
                <Spacer size="xl" />
              </>
            ) }
            <div className="border-y-2 border-solid py-4">
              <Text tag={titleTag === "h1" ? "h2" : "h3"} display="subtitle" size={{ default: "xs", lg: "lg" }} align="center">
              Have a KatKin subscription account? <Link href="/login" className="underline">Log in instead</Link>.
              </Text>
            </div>
            <Spacer size="xl" />
            { body && (
              <Grid>
                <Column spans={{ lg: 6 }} offset={{ lg: 4 }}>
                  <Text tag="div" align="center">
                    { body }
                  </Text>
                  <Spacer size="xl" />
                </Column>
              </Grid>
            ) }
            { showFoodPortionCalculator ? (
              <Grid>
                <Column spans={{ lg: 6 }} offset={{ lg: 4 }}>
                  <FoodPortionCalculator />
                </Column>
              </Grid>
            ) : (
              <Grid>
                <Column justify="center" align="center">
                  <Button
                    id="SectionFoodPortionCalculator_showCalculatorButton"
                    onClick={() => setShowFoodPortionCalculator(true)}
                    color="secondary"
                  >
                    See feeding guidelines
                  </Button>
                </Column>
              </Grid>
            ) }
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionFoodPortionCalculator;
