"use client";

import { FC } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";

import { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Icon from "apps/website/components/base/Icon/Icon";
import Card from "apps/website/components/feature/Card/Card";

export interface ICatCard {
  title: string;
  image: IImageProps;
  link?: string;
}

const CatCard: FC<ICatCard> = ({ title, image, link }) => {
  const router = useRouter();

  const cardFooter = () => (
    <>
      { link && (
        (<Link
          href={link}
          className="flex items-center opacity-100  hover:opacity-70 transition-opacity duration-300"
          aria-label={`Read more about ${title}`}>

          <Text tag="span" className="mr-2">Read more<span className="sr-only"> about { title }</span></Text>
          <Icon icon="arrowRightCircle" size="small" />

        </Link>)
      ) }
    </>
  );

  return (
    <Card
      component="CatCard"
      title={title}
      image={image}
      theme="dark"
      align="center"
      Footer={cardFooter}
      onClick={() => (link ? router.push(link) : console.error("No link defined."))}
      className="transform rotate-0 hover:rotate-1 transition-transform ease-in-out duration-300"
    />
  );
};

export default CatCard;
