import { FC, ReactNode } from "react";

import Tag from "apps/website/components/base/Tag/Tag";
import { Theme } from "apps/website/maps/Theme.map";

interface ISelectOptionTag {
  children: ReactNode;
  theme?: Theme;
}

export const SelectOptionTag: FC<ISelectOptionTag> = ({ children, theme = "pink" }) => (
  <Tag theme={theme} size="small" className="flex absolute -rotate-1 transform left-1/2 -translate-x-1/2 top-0 -translate-y-1/2">
    { children }
  </Tag>
);
