"use client";

import { FC } from "react";
import Balancer from "react-wrap-balancer";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Tag, { ITagProps } from "apps/website/components/base/Tag/Tag";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Button from "apps/website/components/base/Button/Button";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

export interface IAddProductCardPreviewProps {
  tag: ITagProps;
  image: IImageProps;
  title: string;
  body: string;
  buttonText: string;
  buttonLink: string;
}

const AddProductCardPreview: FC<IAddProductCardPreviewProps> = (
  {
    title,
    body,
    image,
    tag,
    buttonLink,
    buttonText,
  },
) => {
  const { chainQueryParamsToUrl } = useQueryParams();
  return (
    <Grid component={AddProductCardPreview.name} gap="none" className="bg-white h-full">
      <Column spans={{ default: 6, lg: 12 }} justify="center" align="center" className="bg-light-grey relative">
        { tag.text && (
          <Tag theme={tag.theme} className="absolute top-4 left-4">{ tag.text }</Tag>
        ) }
        <div>
          <Image image={image} alt={title} />
        </div>
      </Column>
      <Column spans={{ default: 6, lg: 12 }} justify={{ default: "center", lg: "default" }} className="p-2 lg:p-4 h-full">
        <Grid className="h-full" gap="none">
          <Column align="center" justify={{ default: "center", lg: "between" }}>
            <div>
              <Text display="title" size={legacySizeCollectionMap.bodyLg} align="center" data-testid="title">{ title }</Text>
              <Text align="center" size={legacySizeCollectionMap.base} data-testid="body" className="relative after:bg-black after:absolute after:-bottom-2 after:h-[2px] after:w-[30px] after:block after:left-1/2 after:transform after:-translate-x-1/2">
                <Balancer>{ body }</Balancer>
              </Text>
            </div>
            <div className="flex justify-center items-center pt-8">
              <Button to={chainQueryParamsToUrl(buttonLink)} size="fit-content" data-testid="addRemoveButton">
                { buttonText }
              </Button>
            </div>
          </Column>
        </Grid>
      </Column>
    </Grid>
  );
};

export default AddProductCardPreview;
