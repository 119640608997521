"use client";

import { FC, useEffect, useState } from "react";

import {
  ADULT_CAT_AGE_YEARS,
  BREEDS,
  KITTEN_AGE_MONTHS,
  KITTEN_AGE_WEEKS,
  SENIOR_CAT_AGE_YEARS,
  BINARY_GENDER,
  AGE_TYPE,
  LIFESTYLE,
  BODY_TYPE,
} from "libs/cat-calculations/src/lib/cat-calculations.constants";
import {
  Breed,
  AgeType,
  BinaryGender,
  LifeStyle,
  BodyType,
  Portion,
} from "libs/cat-calculations/src/lib/cat-calculations.types";
import {
  calculateCatWeight,
  calculateRetailTrayServings,
  calculateSuggestedCalories,
  makeFractionFromDecimal,
} from "@./cat-calculations";
import { themeRootClassMap } from "apps/website/maps/Theme.map";

import Select from "../../form/Select/Select";
import Input from "../../form/Input/Input";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import RadioSelect from "../../form/RadioSelect/RadioSelect";
import Text from "../../base/Text/Text";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Spacer from "../../layout/Spacer/Spacer";
import Button from "../../base/Button/Button";
import Icon from "../../base/Icon/Icon";

export interface IFoodPortionCalculatorProps {}; // eslint-disable-line
export interface IFoodPortionCalculatorResultProps {
  portions: number;
  calories: number;
}

export interface INumeratorDenominator {
  numerator: number;
  denominator: number;
}
export interface IFraction {
  wholeNumber?: number;
  fraction?: INumeratorDenominator;
}

const FoodPortionCalculatorResult: FC<IFoodPortionCalculatorResultProps> = ({ portions, calories }) => {

  const [ servings, setServings ] = useState<IFraction>();

  useEffect(() => {
    setServings(makeFractionFromDecimal(portions, 4));
  }, [ portions, calories ]);

  return (
    <>
      { servings && (
        <div data-theme="light" className={`px-4 py-6 ${themeRootClassMap.light}`}>
          <Grid gap="none">
            <Column align="center" justify="center" spans={{ default: 6, lg: 4 }}>
              <Text tag="h3" className="uppercase" align="center" size={legacySizeCollectionMap.titleXs}>Your cat needs</Text>
              <Spacer size="sm" />
              <Text display="subtitle" size={legacySizeCollectionMap.titleSm}>{ calories } Kcal/day</Text>
            </Column>
            <Column align="center" justify="center" spans={{ default: 3, lg: 4 }} className="border-l border-r border-mid-grey border-solid">
              <Text tag="h3" className="uppercase" align="center" size={legacySizeCollectionMap.titleXs}>So feed</Text>
              <Spacer size="sm" />
              <div className="flex">
                <Text display="subtitle" size={legacySizeCollectionMap.titleSm}>
                  { servings.wholeNumber && (
                    <>
                      { servings.wholeNumber }
                    </>
                  ) }
                  { (servings.wholeNumber && servings.fraction) && (<>&nbsp;</>) }
                  { servings.fraction && (
                    <>
                      <sup>{ servings.fraction.numerator }</sup>&frasl;<sub>{ servings.fraction.denominator }</sub>
                    </>
                  ) }
              &nbsp; a day
                </Text>

              </div>
            </Column>
            <Column align="center" justify="center" spans={{ default: 3, lg: 4 }}>
              <div className="flex items-center justify-center">
                <Icon icon="tray" />
                <Text display="subtitle" size={legacySizeCollectionMap.titleSm}> = 1</Text>
              </div>
            </Column>
          </Grid>
        </div>
      ) }
    </>
  );
};

const FoodPortionCalculator: FC<IFoodPortionCalculatorProps> = () => {
  const [ breed, setBreed ] = useState<Breed>();
  const [ lifeStage, setLifeStage ] = useState<AgeType>();
  const [ gender, setGender ] = useState<BinaryGender>();
  const [ ageYears, setAgeYears ] = useState<number>();
  const [ ageMonths, setAgeMonths ] = useState<number>();
  const [ ageWeeks, setAgeWeeks ] = useState<number>();
  const [ isValidAge, setIsValidAge ] = useState<boolean>(false);
  const [ weight, setWeight ] = useState<number>();
  const [ lifestyle, setLifestyle ] = useState<LifeStyle>();
  const [ bodyType, setBodyType ] = useState<BodyType>();
  const [ canCalculatePortionSize, setCanCalculatePortionSize ] = useState<boolean>(false);

  const [ suggestedCalories, setSuggestedCalories ] = useState<number>();
  const [ suggestedServings, setSuggestedServings ] = useState<Portion>();

  const [ weightPlaceholder, setWeightPlaceholder ] = useState<string>();

  useEffect(() => {
    setAgeYears(undefined);
    setAgeMonths(undefined);
    setAgeWeeks(undefined);
  }, [ lifeStage ]);

  useEffect(() => {
    setIsValidAge((!!lifeStage && [ "adult", "senior" ].includes(lifeStage) && !!ageYears) || (lifeStage === "kitten" && (!!ageMonths || !!ageWeeks)));
  }, [ lifeStage, ageYears, ageMonths, ageWeeks ]);

  useEffect(() => {
    if (!breed || !lifeStage || !gender) {
      setWeightPlaceholder(undefined);
      return;
    }
    if (!isValidAge) {
      setWeightPlaceholder(undefined);
      return;
    }

    setWeightPlaceholder(`We think they are ${calculateCatWeight(breed, { type: lifeStage, years: ageYears, months: ageMonths, weeks: ageWeeks }, gender)} kg`);

  }, [ breed, lifeStage, ageYears, ageMonths, ageWeeks, isValidAge ]);

  useEffect(() => {
    setCanCalculatePortionSize(isValidAge && !!breed && !!lifeStage && !!weight && !!lifestyle && !!bodyType);
    setSuggestedCalories(undefined);

  }, [ breed, lifeStage, ageYears, ageMonths, ageWeeks, weight, lifestyle, bodyType, isValidAge ]);

  const calculatePortionSize = () => {
    if (weight && bodyType && lifestyle && isValidAge) {
      const calories = calculateSuggestedCalories(
        weight,
        bodyType,
        lifestyle,
        {
          years: ageYears,
          months: ageMonths,
          weeks: ageWeeks,
        },
      );
      setSuggestedCalories(calories);
    } else {
      setSuggestedCalories(undefined);
    }
  };

  useEffect(() => {
    if (suggestedCalories) {
      setSuggestedServings(calculateRetailTrayServings(suggestedCalories).portion);
    } else {
      setSuggestedServings(undefined);
    }
  }, [ suggestedCalories ]);

  return (
    <>
      <div data-component={FoodPortionCalculator.name} className="flex flex-col">
        <Select
          name="life-stage"
          label="Life stage"
          hideLabel={false}
          labelStyle="alternative"
          placeholder="Select life stage"
          options={Object.entries(AGE_TYPE).map(([ value, name ]) => ({ value, name }))}
          onChange={(event) => setLifeStage(event.target.value as AgeType)}
          selected={lifeStage}
        />
        <Spacer size="md" />
        { lifeStage === "kitten" && (
          <>
            <Grid>
              <Column spans={6}>
                <Select
                  name="age-months"
                  label="Months"
                  hideLabel={false}
                  labelStyle="alternative"
                  placeholder="Months"
                  options={KITTEN_AGE_MONTHS.map((month) => ({ name: `${month}`, value: `${month}` }))}
                  onChange={(event) => setAgeMonths(Number(event.target.value))}
                  selected={ageMonths ? `${ageMonths}` : undefined}
                />
              </Column>
              <Column spans={6}>
                <Select
                  name="age-weeks"
                  label="Weeks"
                  hideLabel={false}
                  labelStyle="alternative"
                  placeholder="Weeks"
                  options={KITTEN_AGE_WEEKS.map((week) => ({ name: `${week}`, value: `${week}` }))}
                  onChange={(event) => setAgeWeeks(Number(event.target.value))}
                  selected={ageWeeks ? `${ageWeeks}` : undefined}
                />
              </Column>
            </Grid>
            <Spacer size="md" />
          </>
        ) }
        { (lifeStage && [ "adult", "senior" ].includes(lifeStage)) && (
          <>
            <Select
              name="age-years"
              label="Years"
              hideLabel={false}
              labelStyle="alternative"
              placeholder="Years"
              options={
                lifeStage === "adult" ?
                  ADULT_CAT_AGE_YEARS.map((year) => ({ name: `${year}`, value: `${year}` })) :
                  SENIOR_CAT_AGE_YEARS.map((year) => ({ name: `${year}`, value: `${year}` }))
              }
              onChange={(event) => setAgeYears(Number(event.target.value))}
              selected={ageYears ? `${ageYears}` : undefined}
            />
            <Spacer size="md" />
          </>
        ) }
        <RadioSelect
          name="gender"
          label="Gender"
          hideLabel={false}
          labelStyle="alternative"
          options={Object.entries(BINARY_GENDER).map(
            ([ value, name ]) => ({ value, name, selected: value === gender }),
          )}
          onChange={(event) => setGender(event.target.value as BinaryGender)}
          optionSize="[2,2]"
        />
        <Spacer size="md" />
        <Select
          name="breed"
          label="Breed"
          hideLabel={false}
          labelStyle="alternative"
          placeholder="Select a breed"
          options={BREEDS.map(({ text: name, value }) => ({ name, value }))}
          onChange={(event) => setBreed(event.target.value as Breed)}
          selected={breed}
        />
        <Spacer size="md" />
        <Input
          name="weight"
          label="Weight(KG)"
          type="number"
          hideLabel={false}
          labelStyle="alternative"
          placeholder={weightPlaceholder}
          onChange={(event) => setWeight(Number(event.target.value))}
        />
        <Spacer size="md" />
        <Select
          name="activity"
          label="How active are they?"
          hideLabel={false}
          labelStyle="alternative"
          placeholder="Select level of activity"
          options={Object.entries(LIFESTYLE).map(
            ([ value, name ]) => ({ value, name }),
          )}
          onChange={(event) => setLifestyle(event.target.value as LifeStyle)}
          selected={lifestyle}
          tooltip={{
            buttonName: "Not sure?",
            justify: "end",
            body: <>
              <Text size={legacySizeCollectionMap.body2Xs}><Text tag="span" display="subtitle">Inactive:</Text> Serial snoozer or coach potato</Text>
              <Text size={legacySizeCollectionMap.body2Xs}><Text tag="span" display="subtitle">Active:</Text> Short bursts of energy, indoor or out</Text>
              <Text size={legacySizeCollectionMap.body2Xs}><Text tag="span" display="subtitle">Explorer:</Text> Constant energy indoor or out</Text>
            </> }}
        />
        <Spacer size="md" />
        <RadioSelect
          name="body-condition"
          label="Their body condition currently is..."
          hideLabel={false}
          labelStyle="alternative"
          options={Object.entries(BODY_TYPE).map(
            ([ value, { name, icon } ]) => ({ value, name, icon, selected: value === bodyType }),
          )}
          onChange={(event) => setBodyType(event.target.value as BodyType)}
          optionSize="[4,4]"
        />
        <Spacer size="xl" />
        <Grid>
          <Column justify="center" align="center">
            <Button id="FoodPortionCalculator_calculateButton" color="secondary" onClick={calculatePortionSize} disabled={!canCalculatePortionSize}>Calculate</Button>
          </Column>
        </Grid>

        { (suggestedCalories && suggestedServings) && (
          <>
            <Spacer size="xl" />
            <FoodPortionCalculatorResult calories={suggestedCalories} portions={suggestedServings} />
          </>
        ) }
      </div>
    </>
  );
};

export default FoodPortionCalculator;
