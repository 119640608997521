"use client";

import {
  FC,
  Suspense,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "next/navigation";

import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { useAuth } from "@auth/client-sdk-react";
import { CustomerAPI } from "@api-clients/customer";
import { PublicConfig } from "apps/website/config/public";
import {
  IMySubscriptions,
} from "libs/api/customer/src/lib/types/subscriptions";

import SectionWarningCard from "../../section/SectionWarningCard/SectionWarningCard";
import SectionCheckboxEnabledCTA from "../../section/SectionCheckboxEnabledCTA/SectionCheckboxEnabledCTA";
import {
  TPrecancellationSubscriptionState,
} from "../../section/SectionSplash/SectionPreCancellationSplash/SectionPreCancellationSplash";

export interface IStoryBlokWarningCard {
  title: string;
  subscription_state?: TPrecancellationSubscriptionState;
}

interface IWarningBlock extends Omit<IStoryBlokWarningCard, "subscription_state"> {
  accessToken?: string;
  subscriptionId: string | null;
  subscriptionState?: TPrecancellationSubscriptionState;
}

const WarningBlock: FC<IWarningBlock> = ({ title, subscriptionState, accessToken, subscriptionId }) => {

  const [ userSubscriptions, setUserSubscriptions ] = useState<IMySubscriptions>();

  useEffect(() => {
    if (accessToken) {
      const effectSetSubscriptions = async () => {
        // useAPI with .Customer returns 401?
        const customer = new CustomerAPI(
          PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL,
          accessToken,
          { withCredentials: true },
        );
        const data = await customer.getAllSubscriptions();
        setUserSubscriptions(data);
      };
      void effectSetSubscriptions();
    }
  }, [ accessToken ]);

  return (
    <>
      { (subscriptionId && userSubscriptions?.discountData?.appliesToSubGroups?.[subscriptionId]) ? (
        <SectionWarningCard
          title={title}
          body={
            userSubscriptions.discountData.discount.discount_type === "absolute_total" ?
              `You'll get your next box at a discounted price of £${userSubscriptions.discountData.discount.value.toFixed(2)}, and you'll lose the offer if you cancel.` :
              `You have ${userSubscriptions.discountData.discount.discount_type === "percentage" ?
                `${userSubscriptions.discountData.discount.value}%` :
                `£${userSubscriptions.discountData.discount.value.toFixed(2)}`} off your next box, and you'll lose it if you cancel.`
          }
        />
      ) : subscriptionState === "TRIAL" && (
        <>
          {
            <SectionWarningCard
              title={title}
              body={"You have a free tote bag in your next box. If you cancel, you’ll miss out on it."}
            />
          }
        </>
      ) }
    </>
  );
};

const StoryBlokWarningCardChild: FC<IStoryBlokComponent<IStoryBlokWarningCard>> = ({ blok }) => {
  const { accessToken } = useAuth();
  const searchParams = useSearchParams();

  return (
    <>
      <WarningBlock title={blok.title} subscriptionState={blok.subscription_state} accessToken={accessToken} subscriptionId={searchParams.get("subscriptionId")} />
      <SectionCheckboxEnabledCTA></SectionCheckboxEnabledCTA>
    </>
  );
};

export const StoryBlokWarningCard: FC<IStoryBlokComponent<IStoryBlokWarningCard>> = ({ blok }) => (
  <Suspense>
    <StoryBlokWarningCardChild blok={blok} />
  </Suspense>
);
