import { FC } from "react";

import Text from "apps/website/components/base/Text/Text";

import Icon from "../../base/Icon/Icon";
import Column from "../../layout/Column/Column";
import Grid from "../../layout/Grid/Grid";
import { legacySizeCollectionMap } from "../../base/Text/Text.map";
import Spacer from "../../layout/Spacer/Spacer";

export interface IWarningCard {
  title: string;
  body: string;
  amount?: string;
}

const WarningCard: FC<IWarningCard> = ({ title, body, amount }) => (
  <div data-component={WarningCard.name} className="border bg-white border-orange items-center">
    <Grid gap="none" className="h-28 items-center">
      <Column spans={5} className="bg-orange justify-center items-center h-full">
        <Icon icon={"warning"}></Icon>
        <Text align="center" display="subtitle" size={legacySizeCollectionMap.titleSm}>{ title }</Text>
      </Column>
      <Column spans={7} className="items-center text-center p-2">
        <Text align="center" display="default" size={legacySizeCollectionMap.titleSm}>{ body }</Text>
        { amount && (<><Text align="center" display="subtitle" size={legacySizeCollectionMap.titleMd}>{ amount }</Text><Spacer size="sm"></Spacer></>
        ) }
      </Column>
    </Grid>

  </div>

);

export default WarningCard;
