import { FC } from "react";

import Text from "apps/website/components/base/Text/Text";
import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import Icon from "apps/website/components/base/Icon/Icon";
import {
  Display,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";

import { SelectOptionTag } from "../SelectOptionTag/SelectOptionTag";

interface ICheckableSelectOption {
  icon?: IconSvg;
  image?: IImageProps;
  name: string;
  secondaryText?: string;
  tertiaryText?: string;
  aGroupMemberHasSecondaryText?: boolean;
  aGroupMemberHasTertiaryText?: boolean;
  optionIndex?: number;
  tag?: string
  display?: Display;
  description?: string;
}

export const CheckableSelectOption: FC<ICheckableSelectOption> = (
  {
    icon,
    image,
    name,
    secondaryText,
    tertiaryText,
    aGroupMemberHasSecondaryText,
    aGroupMemberHasTertiaryText,
    tag,
    optionIndex,
    display = "title",
    description,
  },
) => {

  const hasAdvancedData = aGroupMemberHasSecondaryText || aGroupMemberHasTertiaryText || tag || icon;
  const wrapperComputedClasses = image ?
    "flex p-4 flex-col h-full"
    : hasAdvancedData
      ? `flex-col h-full w-full px-2 transform ${(!icon && !image) && ((optionIndex ?? 0) % 2 === 0 ? "rotate-1" : "-rotate-1")} ${(!icon && !image) && "pb-2 pt-4 py-12 lg:py-16"}`
      : "h-12 rounded-full px-4";

  const detailComputedClasses = hasAdvancedData
    ? "flex flex-col h-full relative"
    : "w-full";
  return (
    <>
      <div className={`${wrapperComputedClasses} bg-white cursor-pointer box-border border-2 flex flex-1 justify-center items-center border-solid border-black peer-checked:bg-black peer-checked:text-white relative`}>
        { tag && (
          <SelectOptionTag>
            { tag }
          </SelectOptionTag>
        ) }
        { (icon || image) ? (
          <>
            { image ? (
              <div className="h-full flex flex-col">
                <Image image={image} alt={name} />
                <Spacer size="md" />
                <TextSubtitle size={ 2 } tag="h2" align="center" color="inherit">{ name }</TextSubtitle>
                { description && <TextBody size={ 2 } align="center" color="inherit">{ description }</TextBody> }

              </div>
            ) : (
              <div className = { detailComputedClasses }>
                { (icon) ? (
                  <>
                    <Icon icon={ icon as IconSvg } color="inherit" size="radio" />
                  </>
                ) : null }
              </div>
            ) }
          </>
        ) : <Text tag="span" display={ display } align="center" color="inherit" size={ display === "subtitle" ? "xs" : "default" } className="block first-letter:capitalize">{ name }</Text> }
        { aGroupMemberHasSecondaryText && (<Text tag="span" align="center" color="inherit" size="sm" className="pt-2">{ secondaryText || <>&nbsp;</> }</Text>) }
        { aGroupMemberHasTertiaryText && (<Text tag="span" size="2xs" align="center" color="inherit" className="pt-2">{ tertiaryText || <>&nbsp;</> }</Text>) }
      </div>
      { (icon) && (
        <>
          <Spacer size="sm" />
          <Text tag="span" align="center" color="inherit" size={legacySizeCollectionMap.bodySm}>{ name }</Text>
        </>
      ) }
    </>
  );
};
