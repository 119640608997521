"use client";

import { FC, memo } from "react";

import Section from "apps/website/components/layout/Section/Section";

import FeatureWrapperContainer, {
  ISectionFeatureWrapperContainer,
} from "./SectionFeatureWrapperContainer/SectionFeatureWrapperContainer";

export interface ISectionFeatureWrapper extends ISectionFeatureWrapperContainer {
  contain?: boolean;
}

const SectionFeatureWrapper: FC<ISectionFeatureWrapper> = ({ title, subtitle, body, bodySuffix, footerTitlePrefix, footer, footerBody, theme = "default", children, cta, contain }) => (
  <Section component="SectionFeatureWrapper" theme={contain ? undefined : theme} size={(contain || theme === "default") ? "none" : "xl"}>
    <FeatureWrapperContainer
      title={title}
      subtitle={subtitle}
      body={body}
      bodySuffix={bodySuffix}
      footerTitlePrefix={footerTitlePrefix}
      footer={footer}
      footerBody={footerBody}
      theme={theme}
      cta={cta}
    >
      { children }
    </FeatureWrapperContainer>
  </Section>
);

export default memo(SectionFeatureWrapper);
