import { FC, ReactNode } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Tag, { ITagProps } from "apps/website/components/base/Tag/Tag";
import Text from "apps/website/components/base/Text/Text";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Modal from "apps/website/components/feature/Modal/Modal";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

export interface ITextImageModal {
  isOpen: boolean;
  onClose(): void;
  modalId: string;
  modalTitleId: string;
  modalDescriptionId: string;
  tag?: ITagProps;
  title: string;
  tagline?: string;
  body: string;
  image: IImageProps;
  component: string;
  children?: ReactNode;
}

const TextImageModal: FC<ITextImageModal> = ({
  isOpen,
  onClose,
  modalId,
  modalTitleId,
  modalDescriptionId,
  tag,
  title,
  tagline,
  body,
  image,
  component = "TextImageModal",
  children,
}) => (
  <Modal
    component={component}
    onClose={onClose}
    id={modalId}
    isOpen={isOpen}
    modalTitleId={modalTitleId}
    modalDescriptionId={modalDescriptionId}
  >
    <Grid gap="none">
      <Column spans={{ lg: 6 }} className="relative bg-light-grey lg:order-last">
        <Image image={image} alt={title} cover className="hidden lg:block" />
        <Image image={image} alt={title} className="lg:hidden" />
      </Column>
      <Column spans={{ lg: 6 }} className="lg:max-h-[90vh] lg:overflow-auto">
        <Grid>
          <Column spans={{ default: 1, lg: 2 }}>&nbsp;</Column>
          <Column spans={{ default: 10, lg: 8 }}>
            { tag && (
              <>
                <Spacer size="3xl" />
                <Tag theme={tag.theme}>{ tag.text }</Tag>
              </>
            ) }
            <Spacer size="2xl" />
            <Text id={modalTitleId} tag="h2" size={legacySizeCollectionMap.titleLg} display="title">{ title }</Text>
            { tagline && (
              <>
                <Spacer size="md" />
                <Text tag="h3" size={legacySizeCollectionMap.base} display="title">{ tagline }</Text>
              </>
            ) }
            <Spacer size="lg" />
            <Text tag="div" whitespace={{ lg: "preWrap" }}>
              <div id={modalDescriptionId} dangerouslySetInnerHTML={{ __html: body }} />
            </Text>
            <Spacer size="lg" />
            <div data-children>
              { children }
            </div>
            <Spacer size="3xl" />
          </Column>
          <Column spans={{ default: 1, lg: 2 }}>&nbsp;</Column>
        </Grid>
      </Column>
    </Grid>
  </Modal>
);

export default TextImageModal;
