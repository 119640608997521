"use client";

import {
  createRef,
  FC,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import Button from "apps/website/components/base/Button/Button";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import useA11y from "apps/website/hooks/useA11y";
import useWindowSize from "apps/website/hooks/useWindowSize";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import { booleanToState } from "apps/website/utils/misc/state";
import Section from "apps/website/components/layout/Section/Section";
import FeatureWrapperContainer, {
  ISectionFeatureWrapperContainer,
} from "apps/website/components/section/SectionFeatureWrapper/SectionFeatureWrapperContainer/SectionFeatureWrapperContainer";

import styles from "./SectionFeatureWrapperGroup.module.css";

export interface ISectionFeatureWrapperGroup {
  items: ISectionFeatureWrapperContainer[];
  contain?: boolean;
}

const SectionFeatureWrapperGroup: FC<ISectionFeatureWrapperGroup> = ({ items }) => {

  const { windowSize } = useWindowSize();
  const { UUID } = useA11y();

  const refsByIndex = useMemo(() => {
    const refs: Record<number, RefObject<HTMLDivElement>> = {};
    items.forEach((item, index) => {
      refs[index] = createRef();
    });
    return refs;
  }, [ items ]);

  const [ isOpen, setIsOpen ] = useState<boolean[]>([]);
  const [ contentHeight, setContentHeight ] = useState<string[]>([]);
  const [ animationClasses, setAnimationClasses ] = useState("");

  const updateContentHeight = useCallback(() => {
    const heights: string[] = [];
    setAnimationClasses("duration-0");
    Object.values(refsByIndex).forEach((content) => {
      if (content.current) { // For some reason optional chaining below isn't playing nicely in StoryBlok
        heights.push(`${content.current?.offsetHeight}px`);
      }
    });
    setContentHeight(heights);
  }, [ refsByIndex ]);

  const handleToggle = useCallback((index: number) => {
    setAnimationClasses("transition-all duration-500");
    const state = isOpen;
    state[index] = !state[index];
    setIsOpen([ ...state ]);
  }, [ isOpen ]);

  useEffect(() => {
    setIsOpen(items.map((item, index) => (index === 0 || item.disableToggle || false)));
  }, [ items ]);

  useEffect(() => {
    updateContentHeight();
  }, [ windowSize, refsByIndex, items, updateContentHeight ]);

  const toggleNext = (index: number, text: string) => <> { index < items.length - 1 && (
    <>
      <Spacer size="xl" />
      <Grid>
        <Column justify="center" align="center">
          <Button
            id={`${UUID}-button-${index + 1}`}
            onClick={() => handleToggle(index + 1)}
            ariaControls={`${UUID}-content-${index + 1}`}
            ariaExpanded={isOpen[index + 1]}
          >
            { text || "Toggle next" }
          </Button>
        </Column>
      </Grid>
    </>
  ) }</>;

  return (
    <Section component="SectionFeatureWrapperGroup" size="none">
      { items.map((item, index) => (
        <div
          key={index}
          data-state={booleanToState(isOpen[index])}
          className={`overflow-hidden ${animationClasses} ${styles.content} ${item.contain ? undefined : themeRootClassMap[item?.theme ?? "default"]}`}
          style={{ "--content-height": contentHeight[index] } as React.CSSProperties}
        >
          <div
            ref={refsByIndex[index]}
            id={`${UUID}-content-${index}`}
            aria-hidden={!isOpen[index]}
            aria-labelledby={`${UUID}-button-${index}`}
          >
            <FeatureWrapperContainer
              title={item.title}
              subtitle={item.subtitle}
              body={item.body}
              bodySuffix={item.bodySuffix}
              footer={item.footer}
              footerBody={item.footerBody}
              theme={item.theme}
              cta={item.cta}
              ToggleNext={() => toggleNext(index, item?.toggleSiblingText ?? "")}
              padding={ items[index - 1]?.theme === item.theme ? { top: "none", bottom: "xl" } : "xl" }
              onContentSizeChange={updateContentHeight}
              disableToggle={item.disableToggle}
            >
              { item.children }
            </FeatureWrapperContainer>
          </div>
        </div>
      )) }
    </Section>
  );
};

export default memo(SectionFeatureWrapperGroup);
