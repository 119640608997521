import { FC, ReactNode } from "react";

import Column from "apps/website/components/layout/Column/Column";

export interface IError {
  message?: string;
  action?: "LOGIN";
  children: ReactNode;
}

const Error: FC<IError> = ({ children }) => (
  <Column component="Error" className="border-2 border-solid border-orange bg-white p-2 box-border items-center justify-center w-full">
    { children }
  </Column>
);

export default Error;
