import { FC, ChangeEvent, ReactNode } from "react";
import clsx from "clsx";

import useA11y from "apps/website/hooks/useA11y";
import { IFormFieldSelectOptionAdvancedOptions } from "apps/website/types/form";

import Label, { TLabelLayout } from "../Label/Label";
import { FontStyle } from "../Label/Label.map";
import Tooltip, { ITooltip } from "../../feature/Tooltip/Tooltip";
import { Align, Display } from "../../base/Text/Text.map";
import { ValidInput } from "../ValidInput/ValidInput";

import { SelectOptionTag } from "./SelectOptionTag/SelectOptionTag";

export interface ISelectOption {
  name: string;
  value: string;
  selected?: boolean;
  advancedOptions?: IFormFieldSelectOptionAdvancedOptions;
  display?: Display;
}

export interface ISelect {
  name: string;
  placeholder?: string;
  label: string;
  labelStyle?: FontStyle;
  hideLabel?: boolean;
  labelPosition?: Align;
  options: ISelectOption[];
  optional?: boolean;
  selected?: string;
  allowMultipleSelect?: boolean;
  onChange(event: ChangeEvent<HTMLSelectElement>): void;
  component?: string;
  className?: string;
  tabbable?: boolean;
  children?: ReactNode;
  tooltip?: ITooltip;
  isValid?: boolean;
  labelLayout?: TLabelLayout;
  onBlur?:() => void;
  preserveLabelWhiteSpace?: boolean;
  shouldLabelWrap?: boolean;
}

const Select: FC<ISelect> = ({ name, onChange, label, labelStyle, placeholder, hideLabel = true, selected, allowMultipleSelect = false, options, optional = false, className, component = "Select", tabbable = true, children, tooltip, labelPosition = "default", isValid, labelLayout = "default", onBlur, preserveLabelWhiteSpace = false, shouldLabelWrap = true }) => {
  const { UUID } = useA11y();
  return (
    <div data-component={component} className={className} data-theme="form-input">
      { (tooltip && tooltip.placement === "above") && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
      <Label
        label={label}
        fontStyle={labelStyle}
        hideLabel={hideLabel}
        align={labelPosition}
        UUID={UUID}
        optional={optional}
        layout={labelLayout}
        shouldLabelWrap={ shouldLabelWrap }
        preserveLabelWhiteSpace={ preserveLabelWhiteSpace }
      >
        <div className="relative">
          <select
            id={UUID}
            className={clsx(
              "box-border border-black border-2 font-display font-sentence w-full h-10 pl-4 pr-12 bg-white rounded-full appearance-none lg:min-h-[50px] text-xs",
              "bg-[right_1rem_center] bg-no-repeat",
              !selected && "text-mid-grey",
            )}
            style={{ backgroundImage: "url('/images/forms/fields/select-chevron.svg')" }}
            name={name}
            value={selected}
            onChange={onChange}
            multiple={allowMultipleSelect}
            tabIndex={tabbable ? 0 : -1}
            onBlur={onBlur}
          >
            <option value={placeholder} hidden>{ placeholder }</option>
            { options.map((option) => (
              <option key={option.value} value={option.value}>
                { option.name }
                { option.advancedOptions?.secondaryText && (<> - { option.advancedOptions?.secondaryText }</>) }
              </option>
            )) }
          </select>
          { options.map((option) => (
            <>
              { (option.selected && option?.advancedOptions?.tag) && (
                <SelectOptionTag>
                  { option.advancedOptions?.tag }
                </SelectOptionTag>
              ) }
            </>
          )) }
          <ValidInput isValid={isValid} input="select" />
        </div>
      </Label>
      { children }
      { (tooltip && (!tooltip.placement || tooltip.placement === "default")) && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
    </div>
  );
};

export default Select;
