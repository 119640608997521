export type Breed = "DOMESTIC_SHORT_HAIR" | "DOMESTIC_MEDIUM_HAIR" | "DOMESTIC_LONG_HAIR" | "ABYSSINIAN" | "AMERICAN_BOBTAIL" | "AMERICAN_CURL" | "AMERICAN_SHORTHAIR" | "BALINESE" | "BENGAL" | "BIRMAN" | "BRITISH_SHORTHAIR" | "BRITISH_LONGHAIR" | "BURMESE" | "BURMILLA" | "CHAUSIE" | "CHINCHILLA" | "CORNISH_REX" | "DEVON_REX" | "EXOTIC_SHORTHAIR" | "JAPANESE_BOBTAIL" | "KORAT" | "KURILIAN_BOBTAILS" | "LAPERM" | "MAINE_COON" | "MANX" | "MOGGIE" | "NORWEGIAN_FOREST_CAT" | "OCICAT" | "ORIENTAL_SHORTHAIR" | "PERSIAN" | "PETERBALD" | "PIXIE_BOB" | "RAGAMUFFIN" | "RAGDOLL" | "RUSSIAN_BLUE" | "SAVANNAH" | "SCOTTISH_FOLD" | "SIAMESE" | "SIBERIAN_FOREST_CAT" | "SINGAPURA" | "SNOWSHOE" | "SOMALI" | "SPHYNX" | "TIFFANIE" | "TONKINESE" | "TURKISH_ANGORA" | "TURKISH_VAN" | "OTHER" | "I_DONT_KNOW_THEIR_BREED";

export interface IBreed {
  value: Breed;
  text: string;
  maleAdultWeight: number;
  femaleAdultWeight: number;
  isMoggie: boolean;
  isPedigree: boolean;
}

export enum ECatResides {
  INDOOR = "INDOOR",
  OUTDOOR = "OUTDOOR",
}

export type AgeType = "kitten" | "adult" | "senior";
export type Gender = "BOY" | "GIRL" | "UNKNOWN";
export type BinaryGender = "BOY" | "GIRL";
export type LifeStyle = "INACTIVE" | "ACTIVE" | "EXPLORER" | "UNKNOWN";
export type BodyType = "THIN" | "SKINNY" | "JUST_RIGHT" | "CHUBBY" | "OBESE" | "UNKNOWN";
export type PouchSize = "A" | "B" | "C" | "D" | "E" | "F";
export type Portion = 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2;

type TBodyTypeIcon = "catWeightChubby" | "catWeightJustRight" | "catWeightObese" | "catWeightSkinny";

export interface IBodyType {
  name: string;
  icon: TBodyTypeIcon;
}

export type BodyTypeCalorieMultiplier = Record<BodyType, number>;
export type LifeStyleCalorieMultiplier = Record<LifeStyle, number>;
export type MealPlanCalories = Record<PouchSize, number>;
export type RetailTrayPortions = Record<number, Portion>;

export interface IMealPlan {
  size: PouchSize;
  calories: number;
}

export interface IRetailTrayPortion {
  portion: Portion;
  calories: number;
}

export interface ICatAge {
  type?: AgeType;
  years?: number;
  months?: number;
  weeks?: number;
}

export interface INumeratorDenominator {
  numerator: number;
  denominator: number;
}
export interface IFraction {
  wholeNumber?: number;
  fraction?: INumeratorDenominator;
}

export type TDecimalRounding = "UP" | "DOWN";
