export enum Interpolator {
  FRONTEND,
  BACKEND,
}

/**
 * Recursively replaces strings in an object/string/array
 * @param x JSON object to replace strings with
 * @param replacements String to string map of replacements
 */
export const interpolate = <T>(x: T, replacements: Record<string, string>): T => {

  if (Array.isArray(x)) {
    return x.map((item) => interpolate(item, replacements)) as unknown as T;
  }

  if (typeof x === "string") {
    // Folds over the replacements object with the replaced string as an accumulator
    return interpolateString(x, replacements) as T;
  }

  if (x !== null && x !== undefined && typeof x === "object") {
    return Object.fromEntries(
      Object.entries(x).map(([ key, value ]) => [ key, interpolate(value, replacements) ]),
    ) as unknown as T;
  }

  return x;
};

export const interpolateString = (input: string, variables: Record<string, string>, stack?: Interpolator): string => {
  if (!input) return "";
  const occurences = findInterpolationOccurances(input, stack);

  let output = input;
  for (const occurence of occurences) {
    output = output.replaceAll(occurence, evaluateOccurence(occurence, variables));
  }

  return output;
};
export const findInterpolationOccurances = (input: string, stack = Interpolator.BACKEND): string[] => {
  const matchRegex = stack === Interpolator.BACKEND ? /{{\s*.*?}}/g : /\[\[(.*?)\]\]/g;
  const matches = [ ...input.matchAll(matchRegex) ];

  return matches.map((m) => m[0]);
};

export const evaluateOccurence = (occurence: string, variables: Record<string, string>): string => {

  // Find what the variable name is
  const variableMatch = occurence.match(/(\$[A-Za-z0-9]+)(\s+\|\|([A-Za-z0-9\s]+))?/);
  if (variableMatch === null) return "";

  const variable = variableMatch[1];

  // If the variable exists in our context, return the variable value
  if (variables[variable] !== undefined) return variables[variable];

  // Otherwise attempt to use the fallback if it exists
  const fallbackMatch = variableMatch?.[3] ?? "";
  if (fallbackMatch !== null) {
    return fallbackMatch.trim();
  }

  return "";
};
