import { FC, memo } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import {
  rotationClassList,
} from "apps/website/components/feature/TeamMember/TeamMember.map";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

export interface ITeamMember {
  memberName: string;
  memberImage: IImageProps;
  petImage?: IImageProps;
  petName?: string;
  theme?: Theme;
}

const TeamMember: FC<ITeamMember> = ({ memberName, memberImage, petImage, petName, theme = "default" }) => {

  const rotationClass = rotationClassList[Math.floor(Math.random() * rotationClassList.length)];

  return (
    <div data-component="TeamMember" data-theme={theme} className={`relative aspect-[3/4] m-2 transform ${rotationClass}`}>
      <div className="w-full h-full group flex items-center justify-center">
        <div className={`relative w-[calc(100%-5px)] h-[calc(100%-5px)] ${themeRootClassMap[theme]}`}>
          <Image image={memberImage} alt={memberName} cover lazy={false}/>
          { (petImage && petName) && (
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
              <Image image={petImage} alt={petName} cover />
            </div>
          ) }
        </div>
        <Image image={{
          src: "/images/features/team/frame.png",
          height: 232,
          width: 183,
        }} alt=""
        cover />
      </div>
    </div>
  );
};

export default memo(TeamMember);
