import { FC, ReactNode } from "react";
import Link from "next/link";

import useA11y from "apps/website/hooks/useA11y";
import Icon from "apps/website/components/base/Icon/Icon";
import Tooltip from "apps/website/components/feature/Tooltip/Tooltip";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
  Size,
  BreakpointSize,
} from "apps/website/components/base/Text/Text.map";

import { ICheckbox } from "../Checkbox";

interface ISimpleCheckbox extends ICheckbox {
  advancedLabel?: ReactNode;
  hideCheckbox?: boolean;
  labelSize?: Size | BreakpointSize;
}

const SimpleCheckbox: FC<ISimpleCheckbox> = ({ onChange, name, value, checked = false, optional, label, labelLink, advancedLabel, className, component = "SimpleCheckbox", tabbable = true, tooltip, hideCheckbox = false, labelSize = legacySizeCollectionMap.body2Xs }) => {
  const { UUID } = useA11y();

  return (
    <div data-component={component} className={className} data-theme="form-input">
      { (tooltip && tooltip.placement === "above") && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
      <label tabIndex={0} className="relative flex items-center">
        <input
          type="checkbox"
          id={UUID}
          name={name}
          value={value}
          onChange={(event) => !hideCheckbox && onChange(event)}
          defaultChecked={checked}
          className="absolute left-1/2 -translate-x-1/2 w-full h-full peer rounded-md cursor-pointer appearance-none"
          tabIndex={tabbable ? 0 : -1}
        />
        { !hideCheckbox && (
          <span className="block rounded border border-black border-solid w-6 h-6 box-border peer-checked:bg-black mr-4 text-white [&>*]:hidden peer-checked:[&>*]:block">
            <Icon icon="tickAlt" color="inherit" size="fit" />
          </span>
        ) }
        <Text tag="span" size={labelSize} className="flex-1">

          { label }{ labelLink?.link?.url && (
            <>
              { " " }
              <Link href={labelLink.link.url} className="underline relative" target="_blank" rel="nofollow noopener">
                { labelLink.text }
              </Link>
            </>
          ) }
          { advancedLabel && (
            <>{ advancedLabel }</>
          ) }
          { !optional && "*" }
        </Text>
      </label>
      { (tooltip && (!tooltip.placement || tooltip.placement === "default")) && (
        <div className="h-4 pt-2">
          <Tooltip id={ UUID } { ...tooltip } />
        </div>
      ) }
    </div>
  );
};

export default SimpleCheckbox;
