"use client";

import { FC } from "react";
import clsx from "clsx";

import { ILink } from "apps/website/components/base/Button/Button.map";
import { useQueryParams } from "apps/website/hooks/useQueryParams";
import { DisplayState } from "@/constants/state";

import Button from "../../base/Button/Button";
import Text from "../../base/Text/Text";
import Section from "../../layout/Section/Section";
import Container from "../../layout/Container/Container";
import Image from "../../base/Image/Image";

export interface ISectionStickyCTAProps {
  cta?: ILink;
  secondaryCta?: ILink;
  text?: string;
  showTrustPilotRating?: boolean;
  disabled?: boolean;
  state?: DisplayState;
  onClick?: () => void;
}

const SectionStickyCTA: FC<ISectionStickyCTAProps> = ({
  cta,
  secondaryCta,
  text,
  showTrustPilotRating = true,
  disabled,
  state,
  onClick,
}) => {
  const { chainQueryParamsToUrl } = useQueryParams();

  return (
    <>
      <Section
        component={ SectionStickyCTA.name }
        theme="brand"
        size="sm"
        className={ clsx(
          "fixed bottom-0 left-0 w-full p-4 z-30",
        ) }>
        <Container
          className="flex items-center justify-center flex-col-reverse md:flex-row"
        >
          <div className={ `flex items-center justify-center flex-row md:flex-col md:px-4 mt-2 md:mt-0 ${!showTrustPilotRating && "hidden"}` }>
            <Text size="sm" align="center" className="flex-none md:flex-auto mr-2 md:mr-0">
              { text }
            </Text>
            <div className="w-40">
              <Image
                className="flex items-center align-center"
                image={ {
                  src: "https://a.storyblok.com/f/176726/x/f4167b328f/trustpilot-5-stars.svg",
                  width: 200,
                  height: 60,
                } }
                alt="Trustpilot Stars"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <Button
              data-testid={ cta?.testId }
              to={ cta?.to ? chainQueryParamsToUrl(cta.to) : undefined }
              withIcon={cta?.withIcon}
              onClick={ onClick }
              color="secondary"
              disabled={ disabled }
              state={ state }
            >
              { cta?.name }
            </Button>

            { secondaryCta ? (
              <Button
                data-testid={ secondaryCta.testId }
                to={ secondaryCta.to ? chainQueryParamsToUrl(secondaryCta.to) : undefined }
                withIcon={ secondaryCta.withIcon }
                disabled={ disabled }
                state={ state }
                design="underline"
              >
                { secondaryCta.name }
              </Button>
            ) : null }
          </div>
        </Container>
      </Section>
    </>
  );
};

export default SectionStickyCTA;
