"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import Carousel from "apps/website/components/feature/Carousel/Carousel";
import Image, { IImage } from "apps/website/components/base/Image/Image";

export interface IImageCarousel {
  images: IImage[]
}

const ImageCarousel: FC<IImageCarousel> = ({ images }) => (
  <Carousel component="ImageCarousel" breakpoints={{ default: 1 }} pagination={false} navigationStyle="secondary">
    { images.map((image, index) => (
      <SwiperSlide key={`image-${index}`}>
        <Image {...image} alt={image.alt} />
      </SwiperSlide>
    )) }
  </Carousel>
);

export default ImageCarousel;
