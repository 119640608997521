"use client";

import React, { FC } from "react";

import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";

import ImageCarousel from "../../feature/Carousel/ImageCarousel/ImageCarousel";
import { IImageProps } from "../../base/Image/Image";

export interface ISectionImageCarousel {
  images: IImageProps[];
}

const SectionImageCarousel: FC<ISectionImageCarousel> = ({ images }) => (
  <Section component="SectionImageCarousel" theme="default">
    <Container className="flex justify-center" size="sm" >
      <div className="relative aspect-square overflow-hidden">
        <ImageCarousel
          images={images.map((image) => ({
            image,
            alt: "Image carousel",
          }))}
        />
      </div>
    </Container>
  </Section>
);

export default SectionImageCarousel;
