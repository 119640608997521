import { fbcFromFbclidQueryParam } from "@capi/types";
import { IActionRequestTrackingInfo } from "@forms/schema";

export function getActionTrackingInfo(
  cookies: {
    _fbp?: unknown;
    _fbc?: unknown;
    test_event_code?: unknown;
    ttclid?: unknown;
    _ttp?: unknown;
  },
): IActionRequestTrackingInfo {
  return {
    fbp: cookies._fbp?.toString(),
    fbc: fbcFromFbclidQueryParam(window.location.href, console.error)?.fbc || cookies._fbc?.toString(),
    test_event_code: cookies.test_event_code?.toString(),
    ttclid: cookies.ttclid?.toString(),
    _ttp: cookies._ttp?.toString(),
  };
}
