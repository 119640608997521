import clsx from "clsx";
import { FC } from "react";

import Text from "apps/website/components/base/Text/Text";

interface IBasicSelectOption {
  name: string;
}

export const BasicSelectOption: FC<IBasicSelectOption> = (
  {
    name,
  },
) => (
  <>
    <div className={clsx(
      "flex flex-1 items-center group",
    )}>
      <div className="flex justify-center items-center">
        <div className="flex justify-center items-center border border-mid-grey border-2 peer-checked:group-[]:border-black h-4 w-4 rounded-full">
          <div className="h-full w-full bg-black rounded-full border-2 border-white border-solid hidden peer-checked:group-[]:block"></div>
        </div>
      </div>
      <div className="flex flex-col pl-4">
        <div className="flex space-x-2 items-center">
          <Text tag="span" size="sm">{ name }</Text>
        </div>
      </div>
    </div>
  </>
);
