import clsx from "clsx";
import { FC } from "react";

import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Tag from "apps/website/components/base/Tag/Tag";

interface IConfirmableSelectOption {
  name: string;
  secondaryText?: string;
  tertiaryText?: string;
  tag?: string;
  current?: boolean;
}

export const ConfirmableSelectOption: FC<IConfirmableSelectOption> = (
  {
    name,
    secondaryText,
    tertiaryText,
    tag,
    current,
  },
) => (
  <>
    <div className={clsx(
      "bg-white cursor-pointer box-border border-mid-grey border-2 p-4 flex flex-1 items-center border-solid justify-between",
      "peer-checked:border-black relative group",
    )}>
      { current && (
        <Tag theme="dark" size="small" className="absolute right-0 bottom-0">Current</Tag>
      ) }
      <div className="flex flex-col pr-4">

        <div className="flex space-x-2 items-center">
          <Text tag="span" display="subtitle" size={legacySizeCollectionMap.titleSm} whitespace="noWrap">{ name }</Text>
          { tag && (
            <Tag theme="brand" size="small">{ tag }</Tag>
          ) }
        </div>
        { secondaryText && (
          <>
            <Spacer size="md" />
            <Text tag="span" size={{ default: "3xs", md: "default" }} display="subtitle">{ secondaryText }</Text>
          </>
        ) }
        { tertiaryText && (
          <>
            <Spacer size="md" />
            <Text tag="span" size={legacySizeCollectionMap.body2Xs}>{ tertiaryText }</Text>
          </>
        ) }
      </div>
      <div className="flex justify-center items-center">
        <div className="flex justify-center items-center border border-mid-grey border-2 peer-checked:group-[]:border-black h-4 w-4 rounded-full">
          <div className="h-full w-full bg-black rounded-full border-2 border-white border-solid hidden peer-checked:group-[]:block"></div>
        </div>
      </div>
    </div>
  </>
);
