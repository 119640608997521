import { FC, ReactNode } from "react";

import { IImage } from "apps/website/components/base/Image/Image";

export interface ITableHeader {
  header: string;
  image?: IImage;
}

export interface ITable {
  children: ReactNode;
  component?: string;
  className?: string;
}

const Table: FC<ITable> = ({ children, className, component = "Table" }) => (
  <table data-component={component} className={className}>
    <tbody>
      { children }
    </tbody>
  </table>
);

export default Table;
