"use client";

import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { useTrustpilot } from "apps/website/hooks/useTrustpilot";

import TrustPilotStars from "../../misc/TrustPilotStars";

import ReviewOverviewLink, {
  IReviewOverviewLink,
} from "./ReviewOverviewLink/ReviewOverviewLink";

export interface ISectionReviewOverview {
  title: string;
  body: string;
  reviewLinks: IReviewOverviewLink[];
  theme: Theme;
}

const SectionReviewOverview: FC<ISectionReviewOverview> = ({ title, reviewLinks, theme, body }) => {
  const { interpolate } = useTrustpilot();
  return (<Section component={SectionReviewOverview.name} theme={theme}>
    <Container>
      <Grid>
        <Column justify="center" align="center">
          <Text display="title" align="center" size={legacySizeCollectionMap.titleMd}>{ title }</Text>
          <Text align="center" size={legacySizeCollectionMap.bodyLg}>{ interpolate(body) }</Text>
          <Spacer size="lg" />
          <div className="w-48">
            <TrustPilotStars />
          </div>
        </Column>
      </Grid>
      <Spacer size="xl" />
      <Grid tag="ul">
        { reviewLinks.map((link) => (
          <ReviewOverviewLink key={link.id} {...link} />
        )) }
      </Grid>
    </Container>
  </Section>);
};
export default SectionReviewOverview;
