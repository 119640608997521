import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import cx from "apps/website/utils/misc/cx";

export interface ISocialFeedItem {
  item: IImageProps;
}

const SectionSocialFeedItem: FC<ISocialFeedItem> = ({ item }) => (
  <div data-component="SectionSocialFeedItem" className={cx("aspect-square relative overflow-hidden")}>
    <Image image={item} alt="Social media image" />
  </div>
);

export default SectionSocialFeedItem;
