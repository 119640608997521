"use client";

import { FC } from "react";

import { ILink } from "apps/website/components/base/Button/Button.map";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Button from "apps/website/components/base/Button/Button";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

export interface IFreeFlowCTA {
  ctas?: ILink[];
}

const FreeFlowCTA: FC<IFreeFlowCTA> = ({ ctas }) => {
  const { chainQueryParamsToUrl } = useQueryParams();
  return (<>
    { !!ctas?.length && (
      <>
        <Grid data-component={FreeFlowCTA.name}>
          <Column justify="center" direction="row" className="flex-wrap">
            { ctas.map((cta) => (
              <Button key={cta.id} to={cta.keepQueryParams ? chainQueryParamsToUrl(cta.to) : cta.to} color={cta.color} design={cta.design} data-testid={cta.testId} withIcon={cta.withIcon} size={cta.size} className="mx-2 mb-4 last:mb-0">
                { cta.name }
              </Button>
            )) }
          </Column>
        </Grid>
      </>
    ) }
  </>
  );
};

export default FreeFlowCTA;
