"use client";

import { FC, useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";

import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
import { Theme } from "apps/website/maps/Theme.map";

import PriceCalculator from "../../feature/PriceCalculator/PriceCalculator";

export interface ISectionPriceCalculator {
  theme?: Theme;
}

const SectionPriceCalculator: FC<ISectionPriceCalculator> = ({
  theme = "brand",
}) => {
  const posthog = usePostHog();
  const [ mounted, setMounted ] = useState(false);

  useEffect(() => setMounted(true), []);
  return (
    <>
      { mounted && (
        <>
          { posthog.getFeatureFlag("price-calculator") === "test" && (
            <Section component="SectionAdvert" theme={ theme }>
              <Container>
                <PriceCalculator />
              </Container>
            </Section>
          ) }
        </>
      ) }
    </>
  );
};

export default SectionPriceCalculator;
