"use client";

import {
  FC,
  ReactNode,
  memo,
  ComponentType,
  useRef,
} from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  makeTitleFromArray,
  replaceWordsWithSticker,
} from "apps/website/utils/content/text";
import { Size } from "apps/website/components/layout/Spacer/Spacer.map";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Text from "apps/website/components/base/Text/Text";
import { ITitle } from "apps/website/components/page/component.types";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Button from "apps/website/components/base/Button/Button";
import { useResizeObserver } from "apps/website/hooks/useResizeObserver";

import {
  borderlessSectionBackgroundClassMap,
} from "../../SectionIconTextList/Section.map";

export interface ISectionFeatureWrapperContainer {
  title?: ITitle;
  subtitle?: string;
  body?: string;
  bodySuffix?: string;
  footerTitlePrefix?: string;
  footer?: string;
  footerBody?: string;
  theme?: Theme;
  cta?: ILink;
  toggleSiblingText?: string;
  padding?: Size | { top: Size, bottom: Size };
  contain?: boolean;
  ToggleNext?: ComponentType;
  onContentSizeChange?: () => void;
  children: ReactNode;
  disableToggle?: boolean;
}

const SectionFeatureWrapperContainer: FC<ISectionFeatureWrapperContainer> = ({ title, subtitle, body, bodySuffix, footerTitlePrefix, footer, footerBody, theme, children, cta, contain, ToggleNext, padding = "none", onContentSizeChange, disableToggle = false }) => {

  const content = useRef<HTMLDivElement>(null);
  useResizeObserver(content, onContentSizeChange);
  const completeTitle = replaceWordsWithSticker(makeTitleFromArray(title?.lines ?? []), title?.stickerSize ?? "", theme);
  const titleTag = title?.tag ? title?.tag : "h2";

  return (
    <div ref={content}>
      <Container component="SectionFeatureWrapperContainer" className={`${theme && themeRootClassMap[theme]} ${!contain && theme && borderlessSectionBackgroundClassMap[theme]} `} theme={theme}>
        <Spacer size={typeof padding === "object" ? padding.top : padding} />
        <Grid>
          <Column align="center">
            { title && (
              <>
                <Text tag={titleTag} display="title" size={legacySizeCollectionMap.titleLg} align="center">
                  <span dangerouslySetInnerHTML={{ __html: completeTitle }} />
                </Text>
                <Spacer size="xl" />
              </>
            ) }
            { (subtitle || body) && (
              <Grid>
                <Column>
                  { subtitle && (
                    <Text display="subtitle" size={legacySizeCollectionMap.titleMd} align="center" whitespace={{ lg: "preWrap" }}>{ subtitle }</Text>
                  ) }
                  { body && (
                    <>
                      <Spacer size="md" />
                      <Text align="center" size={legacySizeCollectionMap.bodyLg} whitespace={{ lg: "preWrap" }}>{ body }</Text>
                    </>
                  ) }
                  { bodySuffix && (
                    <>
                      <Text align="center" size={legacySizeCollectionMap.bodyLg} whitespace={{ lg: "preWrap" }}>{ bodySuffix }</Text>
                    </>
                  ) }
                  <Spacer size="xl" />
                </Column>
              </Grid>
            ) }
            <div className="w-full box-border md:px-8" data-feature>
              { children }
            </div>
            { footerTitlePrefix && (
              <Grid>
                <Column spans={{ lg: 10 }} offset={{ lg: 2 }}>
                  <Spacer size="2xl" />
                  <Text display="title" size={legacySizeCollectionMap.titleLg} align="center">{ footerTitlePrefix }</Text>
                </Column>
              </Grid>
            ) }
            { footer && (
              <Grid>
                <Column>
                  <Spacer size="xl" />
                  <Text display="subtitle" size={legacySizeCollectionMap.titleMd} align="center" whitespace={{ lg: "preWrap" }}>{ footer }</Text>
                </Column>
              </Grid>
            ) }
            { footerBody && (
              <Grid>
                <Column>
                  <Spacer size="md" />
                  <Text align="center" whitespace={{ lg: "preWrap" }}>{ footerBody }</Text>
                </Column>
              </Grid>
            ) }
            { cta && (
              <>
                <Spacer size="xl" />
                <Button to={cta.to} design={cta.design} color={cta.color} data-testid={cta.testId}>{ cta.name }</Button>
              </>
            ) }
            { (!disableToggle && ToggleNext) && (
              <ToggleNext />
            ) }
          </Column>
        </Grid>
        <Spacer size={typeof padding === "object" ? padding.bottom : padding} />
      </Container>
    </div>
  );
};

export default memo(SectionFeatureWrapperContainer);
