"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import Carousel from "apps/website/components/feature/Carousel/Carousel";
import TeamMember, {
  ITeamMember,
} from "apps/website/components/feature/TeamMember/TeamMember";

export interface ITeamMemberCarousel {
  items: ITeamMember[],
}

const TeamMemberCarousel: FC<ITeamMemberCarousel> = ({ items }) => (
  <Carousel component="TeamMemberCarousel" breakpoints={{ default: 2, md: 3, lg: 8 }} rows={3}>
    { items.map((member) => (
      <SwiperSlide key={member.memberName}>
        <TeamMember {...member} />
      </SwiperSlide>
    )) }
  </Carousel>
);

export default TeamMemberCarousel;
