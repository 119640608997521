"use client";

import { FC } from "react";

import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";

import WarningCard from "../../feature/WarningCard/WarningCard";

export interface ISectionWarningCard {
  title: string;
  body: string;
}

// Also contains the checkbox component for now for ease
const SectionWarningCard: FC<ISectionWarningCard> = ({ title, body }) => <Section component="SectionWarningCard" theme="default">
  <Container>
    <WarningCard body={body} title={title}
    />
  </Container>
</Section>;

export default SectionWarningCard;
