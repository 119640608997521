"use client";

import { FC, Suspense, useMemo } from "react";
import { useSearchParams } from "next/navigation";
import Link from "next/link";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Grid from "apps/website/components/layout/Grid/Grid";
import { Theme } from "apps/website/maps/Theme.map";
import Tag from "apps/website/components/base/Tag/Tag";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

import Section from "../../../layout/Section/Section";
import Container from "../../../layout/Container/Container";
import Column from "../../../layout/Column/Column";
import Text from "../../../base/Text/Text";
import Spacer from "../../../layout/Spacer/Spacer";

export type TPrecancellationSubscriptionState = "TRIAL" | "DEFAULT";

// TODO: Minimal props for a/b test
export interface ISectionPreCancellationSplash {
  theme?: Theme;
  image: IImageProps;
  subscriptionState?: TPrecancellationSubscriptionState;
}

const SuspendedSectionPreCancellationSplash: FC<ISectionPreCancellationSplash> = ({ theme, image, subscriptionState = "DEFAULT" }) => {
  const query = useSearchParams();

  const catName = useMemo(() => {
    if (query?.get("cat")) {
      return query.get("cat");
    }
    return "your cat";
  }, [ query ]);

  const tagText = useMemo(() => (
    subscriptionState === "DEFAULT" ?
      "Can we help?" :
      "Still switching?"
  ), [ catName ]);
  const upperText = useMemo(() => (subscriptionState === "DEFAULT" ? `${catName} stopped eating KatKin? Do they only like some recipes? Not ready for your next box?` : `Is ${catName} still adjusting to KatKin? Fresh is different from other cat foods + cats are naturally wary of new routines. That’s why it can take time to switch.`), [ catName ]);
  const lowerText = useMemo(() => (
    subscriptionState === "DEFAULT" ?
      <Text align={"center"}>Contact us on <Link id="precancellationPhone" className="underline font-bold" href={"tel:02045384144"}>020 4538 4144</Link> or <Link id="precancellationEmail" className="underline font-bold" href={"mailto:meow@katkin.com"}>drop us an email</Link> so we can provide tailored advice for you and { catName }.</Text> :
      <Text align={"center"}>Contact us on <Link id="precancellationPhone" className="underline font-bold" href={"tel:02045384144"}>020 4538 4144</Link> or <Link id="precancellationEmail" className="underline font-bold" href={"mailto:meow@katkin.com"}>meow@katkin.com</Link> so we can provide tailored advice for you and { catName }.</Text>
  ), [ catName ]);

  return (
    <>
      <Section component={SectionPreCancellationSplash.name} theme={theme}>
        <Container>
          <Grid>
            <Column align="center" justify="center">
              <div className={"w-24 h-24"}>
                <Image
                  image={image}
                  alt="image"
                />
              </div>
              <Spacer size="sm"></Spacer>
              <Tag theme="dark" className="max-w-[300px]" whitespace="preWrap" size="medium">{ tagText }</Tag>
              <Spacer size="lg"></Spacer>
              <Text align={"center"}>{ upperText }</Text>
              <Spacer size="sm"></Spacer>
              { lowerText }
            </Column>
          </Grid>
        </Container>
      </Section>
      { subscriptionState === "DEFAULT" && (
        <Section theme="light-grey">
          <Container>
            <Grid>
              <Column>
                <Text size={legacySizeCollectionMap.titleMd} display="title" align="center">{ catName } stopped eating KatKin?</Text>
                <Spacer size="xl"/>
                <Text size={legacySizeCollectionMap.titleLg} display="title" align="center" whitespace="balance">Our top 3 tips to get { catName } eating again</Text>
              </Column>
            </Grid>
          </Container>
        </Section>
      ) }
    </>
  );
};

export const SectionPreCancellationSplash: FC<ISectionPreCancellationSplash> = ({
  theme, image, subscriptionState,
}) => (
  <Suspense>
    <SuspendedSectionPreCancellationSplash theme={theme} image={image} subscriptionState={subscriptionState} />
  </Suspense>
);

export default SectionPreCancellationSplash;
