import Link from "next/link";
import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Column from "apps/website/components/layout/Column/Column";

export interface IReviewOverviewLink {
  id?: string;
  title: string;
  to: string;
  logo: IImageProps;
}

const ReviewOverviewLink: FC<IReviewOverviewLink> = ({ title, to, logo }) => (
  <Column component={ReviewOverviewLink.name} tag="li" spans={{ default: 6, lg: 3 }} justify="center" align="center">
    <Link href={to} rel="noopener nofollow noreferrer" target="_blank">

      <Image image={logo} alt={title} />

    </Link>
  </Column>
);

export default ReviewOverviewLink;
