"use client";

import { FC } from "react";

import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import Text from "apps/website/components/base/Text/Text";
import { SearchBar } from "apps/website/components/feature/SearchBar/SearchBar";
import {
  BatchTestResult,
} from "apps/website/components/feature/BatchTestResult/BatchTestResult";
import {
  BatchLookupState,
  useBatchTestResult,
} from "apps/website/components/feature/BatchTestResult/useBatchTestResultLookup";
import Error from "apps/website/components/form/Error/Error";
import {
  LoadingSpinner,
} from "apps/website/components/form/LoadingSpinner/LoadingSpinner";

import Spacer from "../../layout/Spacer/Spacer";

export interface ISectionBatchTestResultLookup {
  title: string;
  body: string;
  note?: string;
}

const SectionBatchTestResultLookup: FC<ISectionBatchTestResultLookup> = ({ title, body, note }) => {
  const { searchString, setSearchString, state, search, result, errorResult } = useBatchTestResult();
  return (
    <Section theme="brand" component={SectionBatchTestResultLookup.name}>
      <Container className="flex flex-col items-center justify-center">
        <Text tag="h2" size={{ default: "3xl", lg: "4xl" }} display="subtitle" className="uppercase whitespace-normal" align="center">
          { title }
        </Text>
        <Spacer size="xl"></Spacer>
        <Text size={{ default: "default", lg: "xl" }} align="center">
          { body }
        </Text>
        { note && (
          <Text size={{ default: "default", lg: "xl" }} align="center">
            { note }
          </Text>
        ) }
        <Spacer size="xl"></Spacer>
        <SearchBar
          name="BatchNumberSearch"
          prefix="BN:"
          onChange={setSearchString}
          onEnter={search}
          value={searchString}
          type="text"
          placeholder="12345"
        />
        <Spacer size="xl"></Spacer>
        { state === BatchLookupState.IN_PROGRESS && (
          <Container className="flex p-4 justify-center items-center">
            <LoadingSpinner size={38} color="white"></LoadingSpinner>
          </Container>
        ) }
        { state === BatchLookupState.FOUND ? (
          <BatchTestResult
            { ...{ ...result, batchNumber: result?.batchNumber ?? "" }}
          />
        ) : ((state === BatchLookupState.NOT_FOUND || state === BatchLookupState.UNKNOWN_ERROR) && (
          <div>
            <Error>
              <Text size={{ default: "sm" }} className="uppercase" align="center" display="subtitle">
                { errorResult?.errorMessage }
              </Text>
            </Error>
          </div>
        )) }
      </Container>
    </Section>
  );
};

export default SectionBatchTestResultLookup;
