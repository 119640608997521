"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import { IImageProps } from "apps/website/components/base/Image/Image";
import SocialFeedItem from
  "apps/website/components/section/SectionSocialFeed/SectionSocialFeedItem/SectionSocialFeedItem";
import Carousel from "apps/website/components/feature/Carousel/Carousel";

export interface ISocialFeedCarousel {
  items: IImageProps[];
  reverse?: boolean;
}

const SocialFeedCarousel: FC<ISocialFeedCarousel> = ({ items, reverse = false }) => (
  <Carousel
    component="SocialFeedCarousel"
    breakpoints={{ default: 3, md: 6, lg: 10 }}
    showNavigation={false}
    reverseAutoplay={reverse}
    centeredSlides
    loop
    autoPlay

  >
    { items.map((item) => (
      <SwiperSlide key={item.src} >
        <SocialFeedItem item={item}/>
      </SwiperSlide>
    )) }
  </Carousel>
);

export default SocialFeedCarousel;
