import { useEffect, useRef, RefObject } from "react";

export function useResizeObserver(targetRef: RefObject<unknown>, callback?: () => void) {
  const resizeObserver = useRef<ResizeObserver | null>(null);

  useEffect(() => {

    observe(ResizeObserver);

    function observe(ResizeObserver: any) { // eslint-disable-line
      resizeObserver.current = new ResizeObserver((entries: HTMLElement[]) => {
        if (entries) {
          if (callback) {
            callback();
          }
        }
      });
      if (targetRef.current) {
        resizeObserver.current?.observe(targetRef.current as Element);
      }
    }

    return disconnect;
  }, [ targetRef.current ]);

  function disconnect() {
    if (resizeObserver.current) {
      resizeObserver.current.disconnect();
    }
  }
}
