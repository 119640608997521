import { BaseAPI } from "@utils/base-api";

export interface IBatchTestResult {
  batchNumber: string;
  batchDate: string;
  bestBefore: string;
  testResult: BatchTestStatus;
  testAuthority: string;
}

export interface IBatchTestResultError {
  errorMessage: string;
}

export enum BatchTestStatus {
  PASS = "PASS",
  PENDING = "PENDING",
}

export class BatchLookupAPI extends BaseAPI {

  public async getBatchInformation(searchString: string): Promise<IBatchTestResult> {
    const response = await this.axios.get("/batch", {
      params: {
        bn: searchString,
      },
    });
    return response.data;
  }

}
