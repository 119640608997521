import { ChangeEvent, FC, Suspense } from "react";

import { WithTestID } from "apps/website/types";

import IconButton from "../../base/IconButton/IconButton";
import Input from "../../form/Input/Input";
import Text from "../../base/Text/Text";
import { AllowedTypes } from "../../form/Input/Input.map";

export interface SearchBarProps extends WithTestID {
  value?: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onEnter?: () => void;
  placeholder?: string;
  prefix?: string;
  label?: string;
  name: string;
  component?: string;
  type?: AllowedTypes;
}

export const SearchBar: FC<SearchBarProps> = ({
  name,
  value,
  onChange,
  onEnter,
  placeholder,
  prefix,
  component = SearchBar.name,
  "data-testid": testId,
  label,
  type = "text",
}) => (
  <Suspense>
    <Input
      data-testid={testId}
      component={component}
      className="w-10/12 md:w-2/3 lg:w-1/2 relative"
      inputClassName={prefix ? "pl-12" : ""}
      name={name}
      type={type}
      placeholder={placeholder}
      label={label ?? ""}
      hideLabel
      value={value}
      onEnter={onEnter}
      onChange={onChange}
    >
      { prefix && (
        <span className="absolute top-0 right-0 bottom-0 left-0 flex flex-row justify-start items-center px-4 pointer-events-none">
          <Text display="subtitle">
            { prefix }
          </Text>
        </span>
      ) }
      <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-row justify-end items-center px-4 pointer-events-none">
        <IconButton
          label={`Search-${label}`}
          id={`Search-${name}`}
          onClick={onEnter}
          icon="magnifyingGlass"
          size={"small"}
          className="pointer-events-auto"/>
      </div>
    </Input>
  </Suspense>
);
