"use client";

import { FC, useMemo } from "react";
import { useSearchParams } from "next/navigation";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

import { IconSvg } from "../../base/Icon/Icon.map";
import Icon from "../../base/Icon/Icon";
import Text from "../../base/Text/Text";

export interface ISectionQueryStringBannerItemProps {
  conditionKey: string;
  conditionValue: string;
  theme: Theme;
  icon?: IconSvg;
  children: string;
}

export interface ISectionQueryStringBannerProps {
  items: ISectionQueryStringBannerItemProps[];
}

const SectionQueryStringBanner: FC<ISectionQueryStringBannerProps> = ({ items }) => {
  const searchParams = useSearchParams();
  const { queryParamsForInterpolation } = useQueryParams();

  const firstMatchedBanner = useMemo(() => {
    let bannerToReturn;
    for (const bannerItem of items) {
      if (searchParams.get(bannerItem.conditionKey) === bannerItem.conditionValue) {
        bannerToReturn = bannerItem;
        break;
      }
    }

    return bannerToReturn;

  }, [ searchParams ]);

  return (
    <>
      { firstMatchedBanner && (
        <div
          data-component={SectionQueryStringBanner.name}
          role="banner" data-theme={firstMatchedBanner.theme}
          className={`flex justify-center items-center p-2 ${themeRootClassMap[firstMatchedBanner.theme]}`}
        >
          { firstMatchedBanner.icon && <Icon icon={firstMatchedBanner.icon} /> }
          <Text display="title">
            { interpolateString(firstMatchedBanner.children, queryParamsForInterpolation(), Interpolator.FRONTEND) }
          </Text>
        </div>
      ) }
    </>
  );
};

export default SectionQueryStringBanner;
